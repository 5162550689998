import { Component, OnInit } from '@angular/core';
import { ROUTER_CONSTANTS } from 'src/app/core/global/constants/router-constants';
import { ServiceUrlConstants } from 'src/app/core/global/constants/serviceurl-constants';
import { AjaxServices } from 'src/app/core/services/ajax-services/ajax-service.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  privacyData: any;
  CAPrivacyData: any;
  routerConstant = ROUTER_CONSTANTS;

  constructor(private ajaxServices: AjaxServices) { }

  openUrl(url) { window.open(url, "_blank"); }

  ngOnInit() {
  }
}
