import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { InternalLoadGuard } from './core/services/guards/internal-load.guard';
import { ProxyComponent } from './proxy.component';

const routes: Routes = [
  { path: "", component: ProxyComponent,},
  {
    path: "callback",
    canActivate: [InternalLoadGuard],
    component: OktaCallbackComponent,
  },
  {
    path: "csr",
    loadChildren: () =>
    import("./claims-management/claims-management.module").then(
      m => {
        return m.ClaimsManagementModule
      }),
      canLoad: [InternalLoadGuard],
  },
  {
    path: "public",
    loadChildren: () =>
    import("./public/public.module").then(
      m => {
        return m.PublicModule
      })
  }
]
@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
