import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { UtilityService } from './app/core/services/utility.service';
import { PublicNgConfigInterface } from './app/core/global/interface/public-ngconfig-data.model';
import { ServiceUrlConstants } from './app/core/global/constants/serviceurl-constants';

if (environment.production) {
  enableProdMode();
}

// using class method getNgConfigURL to get the ngconfig url for respective environment
const publicNGConfigURL = UtilityService.getNgConfigURL();

const xApiKey = UtilityService.getXApiKey(ServiceUrlConstants.NGCONFIG);
// S28540 - added ngConfig api call here to make okta config values available before Application bootstrap. (Replaces config values from env.pro.ts to get value from API)
// config are coming in ngconfig api, earlier used to pick from environment.prod.ts
fetch(publicNGConfigURL, {
    headers:{
      "x-api-key":xApiKey
    }
  }
).then(async (res) => {
  const ngConfigResponse = await res.json();
  // setting response of ngconfig api in publicNgconfig  (class member of UtilityService)
  UtilityService.setPublicNgConfig(ngConfigResponse as PublicNgConfigInterface);
  // setting log level
  UtilityService.setLogLevel(ngConfigResponse);
  // setting encryption key
  UtilityService.setEncryptionKey(ngConfigResponse);
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => {
      console.error(err);
    });
});
