import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class PublicGuard  {
  constructor(private authService: AuthService, 
    private utilService: UtilityService,
    private router: Router) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.utilService.isCsr) {
        if(this.authService.isFeatureEnabled) {
          return this.authService.checkForAccess();
        }
        return this.router.navigate(['public/featureUnavailable']);
      } else {
        return false;
      }
  }
}
