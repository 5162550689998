import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatField'
})
export class FormatFieldPipe implements PipeTransform {

  transform(value: string, fieldName:string): string {
    if(value && value.length){
      if(fieldName.toLowerCase() === 'ssn'){
        return `${value.slice(0,3)}-${value.slice(3,5)}-${value.slice(5)}`;
       }
  
       if(fieldName.toLowerCase()==='phone'){
        return `${value.slice(0,3)}-${value.slice(3,6)}-${value.slice(6)}`;
       }
       if(fieldName.toLowerCase()==='dob'){
        const dateParts = value.split('-');
        if(dateParts.length===3){
          const year = dateParts[0];
          const month =dateParts[1].padStart(2,'0');
          const day = dateParts[2].padStart(2,'0');
          return `${month}/${day}/${year}`;
        }
       }
    }
     return value;
  }

}
