<div class="footer-background pt-2 mt-1 pb-2">
    <div id="footer" class="section-div container">
        <div class="section-content-div">
            <div class="row px-2">
                <div class="col-md-3 col-xs-12 mt-2">
                    <div>
                        <a class="vene_footer_font" [ngClass]="['effect-underline']" 
                        (click)="openUrl(routerConstant.venerableAnnuityDomainSecure +
                        routerConstant.venerableAnnuityPath.VENERABLE_OVERVIEW)">About Us</a>
                    </div>
                    <div class="mt-2">
                        <a class="vene_footer_font" [ngClass]="['effect-underline']"
                        (click)="openUrl(routerConstant.venerableAnnuityDomainSecure + 
                        routerConstant.venerableAnnuityPath.VENERABLE_EMAILCALL)">Contact Us</a>
                    </div>
                    <div class="mt-2">
                        <a class="vene_footer_font" [ngClass]="['effect-underline']"
                        (click)="openUrl(routerConstant.venerableAnnuityDomainSecure +
                        routerConstant.venerableAnnuityPath.VENERABLE_TERMSANDCONDITION)">
                        Terms &amp; Conditions</a>
                    </div>
                </div>
                <div class="col-md-6 col-xs-12 mt-2">
                    <div>
                        <a class="vene_footer_font" [ngClass]="['effect-underline']"
                        (click)="openUrl(routerConstant.venerableAnnuityDomainSecure +
                        routerConstant.venerableAnnuityPath.VENERABLE_NOTICES_DISCLOSURES)">Important Notices & Disclosures</a>
                    </div>
                    <div class="mt-2">
                        <a class="vene_footer_font" [ngClass]="['effect-underline']"
                        (click)="openUrl(routerConstant.venerableAnnuityDomainSecure +
                        routerConstant.venerableAnnuityPath.VENERABLE_SECURITY)">Security</a>
                    </div>
                    <div class="mt-2">
                        <a class="vene_footer_font" [ngClass]="['effect-underline']"
                        (click)="openUrl(routerConstant.venerableAnnuityDomainSecure +
                        routerConstant.venerableAnnuityPath.PRIVACY_NOTICE)">Privacy</a>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 vene_footer_font footer_bottom">
                    <div class="px-4">West Chester, PA <span class="pipe-seperator">|</span> Des Moines, IA <span class="pipe-seperator">|</span> New York, NY</div>
                </div>
                <div class="col-12 mt-4 vene_footer_font footer_bottom">
                    <p class="px-4">Venerable Insurance and Annuity Company (“VIAC”) and Directed Services LLC are wholly owned subsidiaries of Venerable Holdings, Inc.</p>
                    <p class="px-4">Corporate Solutions Life Reinsurance Company is a wholly owned subsidiary of VIAC.</p>
                </div>
            </div>
        </div>
    </div>
</div>