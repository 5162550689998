<button pButton [label]="btnLabel" [icon]="btnIcon" [type]="type" [iconPos]="iconPos" [class]="btnClass"
    [ngClass]="{
    'ms-1 mb-0 btn-sm': !defaultCss ,  
    'ms-1 mb-2 btn-sm': defaultCss,
    'p-button-secondary': !isPrimary,
     '': isPrimary
     }" 
    [name]="name" [id]="id"
    styleClass="theme-button" (click)="handleClickEvent($event)"  [disabled]="disabled" [class]="colCls">
</button>
