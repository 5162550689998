import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from './core/services/loader.service';
import { UtilityService } from './core/services/utility.service';

@Component({
  template: ''
})
export class ProxyComponent implements OnInit {
   constructor(private router: Router, private utilService: UtilityService, private loaderService: LoaderService) {
   }
   ngOnInit() {
      this.routeProxy();
   }
  routeProxy() {
    this.loaderService.showLoader();
    this.utilService.fetchServiceHost();

    if(this.utilService.isCsr) {
      try{
        this.router.navigate(['csr']);
      }
      finally {
        this.loaderService.hideLoader();
      }
    } else {
      try {
        this.router.navigate(['public']);
      }
      finally {
        // this.loaderService.hideLoader();
      }
    }
  }
}
