import { Component, Input } from '@angular/core';
import { StepperConstants } from 'src/app/core/global/constants/stepper-constants';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {
  
  @Input() activeAction: string;
  @Input() pendingActions?: string[];
  @Input() completedActions?: string[];
  @Input() isPrintPage?: boolean;
  public isVenerable = true;
  public windowOrigin = window.location.origin + StepperConstants.BASE_HREF;
}
