import { Injectable, Injector } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from "@angular/common/http";
import { Observable } from "rxjs";

import { retryWhen, scan, delay, map } from "rxjs/operators";

import { SettingsConstants } from "../../global/constants/settings-constants";

@Injectable()
export class ApiRetryDelayInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen(err => {
        // Retry only for a specific number of times
        return err.pipe(
          scan((a, currErr, AttemptCount) => {
            const retryStatus = [413, 429];
            // tslint:disable-next-line: no-string-literal
            if (currErr && currErr['status']) {
              // tslint:disable-next-line: no-string-literal
              if (!(retryStatus.includes(currErr['status']))) {
                throw currErr;
              } else {
                AttemptCount++;
                if (AttemptCount < SettingsConstants.ServiceRetryCount) {
                  return AttemptCount;
                } else {
                  throw currErr;
                }
              }

            } else {
              AttemptCount++;
              if (AttemptCount < SettingsConstants.ServiceRetryCount) {
                return AttemptCount;

              } else {
                throw currErr;
              }
            }

          }, 0),
          // Retry only with a delay of certain configured seconds
          delay(SettingsConstants.ServiceRetryDelay * 1000)
        );
        // get this number of seconds from cofig file and multiply by 1000 to get the milliseconds
      })
    );
  }
}
