import { Injectable, ErrorHandler } from "@angular/core";
import { AjaxServices } from './ajax-services/ajax-service.service';
import { HttpErrorResponse } from "@angular/common/http";
import { ENVIRONMENTCONFIG } from "src/environments/environment.prod";
import { UtilityService } from "./utility.service";
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  isJsExceptionAllowed = true;
  constructor(private http: AjaxServices, private util: UtilityService) { }

  handleError(error: Error | HttpErrorResponse) {
    if (!(error instanceof HttpErrorResponse)) {
      const stringifiedError = String(error);

      const ignoreList = ['where a stream was expected', // suppressing it because it is expected error whenever there is error is thrown from api
        'Cannot match any routes', // suppressing this error, if the user tries some not registered routes
        'not authenticated', // in case of logout the cognito will throw this erro and it is expected
        "'subscribe' of undefined", // in case of token missing or some api failure, this error wil loccur so suppressing it
        "Cannot read property 'pipe' of undefined"]; // in case of token missing or some api failure, this error wil loccur so suppressing it
      const isExistInIgnoreList = ignoreList.filter(item => {
        return stringifiedError.includes(item);
      }).length;
      if (!isExistInIgnoreList) {
        if (this.util.globalErrors.indexOf(stringifiedError) === -1) {
          this.environmentControl(error, stringifiedError);
        } else {
          console.error(error);
        }
      }
    }
  }

  // show js error based on environment
  environmentControl(error, stringifiedError) {
    let currentServiceObj = this.util.fetchCurrentServiceObj();
    if (!currentServiceObj) {
      this.util.fetchServiceHost();
      currentServiceObj = this.util.fetchCurrentServiceObj();
    }

    if (UtilityService.publicNgConfigData && UtilityService.publicNgConfigData.isJsExceptionLogAllowed) {
      this.http.logMessage(error, "WARN");
      this.util.globalErrors.push(stringifiedError);
      console.error(error);
    } else {
      console.error(error);
    }
  }
}
