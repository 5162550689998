export const LabelsConstants = {
    ENTER_FIRST_NAME: "Enter First Name",
    ENTER_LAST_NAME: "Enter Last Name",
    SEARCH_SSN:'Enter SSN',
    ENTER_CONTRACT_NUMBER: "Enter Contract Number",
    CLAIMS_MANAGEMENT: 'Claims Management',
    ADD_CLAIM:"Add Claim",
    SSN_ERROR: "When searching for an SSN/TIN, you must use a full nine-digit SSN/TIN or only the last four digits of the SSN/TIN.",
    SSN_INVALID_ERROR: "When searching for an SSN, you must use a full nine-digit SSN.",
    UID_ERROR: "Please enter SSN, TIN, Email address, Username or Contract Number.",
    UNKNOWN_ERROR: "Unknown error occurred.",
    SSN_REQ_ERROR: "SSN/TIN is required",
    STATE_REQ: "State is required",
    CITY_REQ: "City is required",
    ZIP_REQ: "Zip is required",
    EMAIL_REQ: "Email is required",
    PRIMARY_PHONE_REQ: "Primary Phone Number is required",
    SSN_IS_REQ_ERROR: "SSN is required",
    CC_REQ_ERROR: "Customer Contract Number is required",
    FIRST_NAME_REQ: "First Name is required",
    LAST_NAME_REQ: "Last Name is required",
    FAST_LAST_NAME_REQ: "First Name and Last Name are required",
    RELATION_TO_DECEASED_REQUIRED: "Relationship to Deceased is required",
    FAST_LAST_NAME_MIN_LEN:
      "A minimum of 2 characters is required in the search.",
    BUTTON: {
      SEARCH: "Search",
      UPDATE: "Update",
      UPDATE_OTP: "Update OTP",
      RESET: "Reset"
    },
    ERROR: {
      SSN_MAX_LENGTH:
        "When searching for an SSN/TIN, you must use a full nine-digit SSN/TIN or only the last four digits of the SSN/TIN."
    },
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    ADDRESS1: "Address 1",
    ADDRESS: "Address",
    ADDRESS_REQUIRED: "Address is Required",
    ADDRESS2: "Address 2",
    ADDRESS2OPTIONAL: "Address 2 (optional)",
    CITY: "City",
    STATE: "State",
    ZIP: "Zip",
    PRIMARY_PHONE_NUMBER: "Primary Phone Number",
    PHONE:"Phone",
    EMAIL: "Email",
    COUNTRY: "Country",
    FORIEGN_ADDRESS_QUESTION: "Is the address a foreign address?",
    MIDDLE_NAME: "Middle Initial",
    DATE_OF_BIRTH: "Date of Birth",
    SOCIAL_SECURITY_NUMBER: "Social Security Number",
    STATE_OF_RESIDENCE: "State of Residence",
    DATE_OF_DEATH: "Date of Death",
    RELATION_TO_DECEASED: "Relationship to Deceased",
    DOB_REQUIRED: "Date of Birth is required",
    DATE_OF_DEATH_REQUIRED: "Date of Death is required",
    STATE_OF_RESIDENCE_REQ: "Resident State is required",
    FIRST_NAME_INVALID: "First Name is invalid",
    LAST_NAME_INVALID: "Last Name is invalid",
    DATE_OF_BIRTH_INVALID: "Date of Birth is invalid",
    COUNTRY_REQ : "Country is Required",
    SSN_IS_INVALID: "SSN is invalid",
    DATE_OF_DEATH_INVALID: "Date of Death is invalid",
    SSN_ERROR_MESSAGE:"We are unable to find a match in our systems. Please update the information on this page or contact Venerable Customer Service.",
    FOREIGN_ADDRESS_QUESTION: "Is the address a foreign address?",
    ZIP_INVALID: "Zip code should be 5 or 9 digits",
    CITY_INVALID: "City name is invalid",
    EMAIL_INVALID: "Email is invalid",
    PRIMARY_PHONE_NUMBER_INVALID: "Primary phone number is invalid",
    FILEACLAIMINFO: "Please confirm who should receive further information on how to file a claim.",
    EMAILNOTE: "Note: Please allow up to 2 business days to be contacted regarding next steps.",
    LINKTOBENE: "All added beneficiaries above will be contacted.",
    CONFIRMATION_TEXT: "Thank you for submitting this notification. Once we are able to verify this information within our records, Venerable will notify the beneficiary or the authorized representative with next steps.</br></br>If you have any questions or concerns, please contact Venerable Customer Service. Thank you.",
    TRANS_EFFECTIVE_DATE: "Transaction Effective Date:",
    CONFIRMATION_NUMBER: "Confirmation Number:",
    REQUEST_TO_WAIT: "Please wait while the request is being processed. Don't close the browser until it is complete",
    STATE_OF_RESIDENCE_MESSAGE: "A long form death certificate may be required for your state.",
    PHISH_ATTEMPT_MESSAGE: "We are unable to find a match in our systems. Please contact Venerable Customer Service.",
    BENEFICIARY_ELECTRONIC_CONSENT: 'By entering your e-mail address and continuing below, you consent to the use of electronic means for delivery of documents and communications related to this claim request. You attest that e-mail, phone number, and mailing address provided are current and accurate. If you do not agree to proceed via electronic means, please <a href="https://www.venerable.com/#/contactus" target="_blank"> <u><b>Contact Us</b></u></a>.'
  };
  