<div id="overlay" class="overlay" *ngIf="showLoader">
  <p-progressSpinner
    [style]="{
      width: '80px',
      height: '80px',
      position: 'absolute',
      top: '43%',
      left: '43%'
    }"
    strokeWidth="4"
  ></p-progressSpinner>
  <div class="loaderMsg">{{loadText}}</div>
</div>
