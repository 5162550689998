export const ROUTER_CONSTANTS = {
  venerableAnnuityDomain: "http://www.venerable.com",
  venerableAnnuityDomainSecure: "https://www.venerable.com",
  venerableAnnuityPath: {
    VENERABLE_OVERVIEW: "/#/aboutus",
    VENERABLE_NEWS: "/#/inthenews",
    VENERABLE_EMAILCALL: "/#/contactus",
    SECURITY: "/security.html",
    PRIVACY_NOTICE: "/#/privacy",
    VENERABLE_TERMSANDCONDITION: "/#/termsconditions",
    HAVE_QUESTION: "/#/faq",
    VENERABLE_COOKIES_POLICY: "/#/cookiespolicy",
    VENERABLE_SECURITY: "/#/security",
    VENERABLE_CA_PRIVACY_POLICY: "/#/caprivacypolicy",
    VENERABLE_NOTICES_DISCLOSURES:"/#/noticesdisclosures" 
  },
};
