import { Pipe, PipeTransform } from '@angular/core';
import { Country } from 'src/app/claims-management/models/country.model';

@Pipe({
  name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {

  transform(value: string,countries: Country[]): string {
      // For fetching Country name from Country code
        if (value && countries && countries.length) {
          const countryNameArr= countries.filter(data => data.countryCode === value);
     
          if(countryNameArr && countryNameArr.length)
            return countryNameArr[0].countryName;
        }

        return null;
  }


}
