import { Injectable } from '@angular/core';
import { Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class InternalLoadGuard  {
  isInternal: boolean;
  isAuthenticated: boolean;
  currentServiceObj;

  constructor(private utilityService: UtilityService) {

  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.canLoadInternal();
  }

  canActivate(): boolean {
    return this.canLoadInternal();
  }
  canLoadInternal(): boolean {
    if (!this.currentServiceObj) {
      this.utilityService.fetchServiceHost();
      this.currentServiceObj = this.utilityService.fetchCurrentServiceObj();
    }
    if (!this.utilityService.isCsr) {
      return false;
    } else if (this.utilityService.isCsr) {
      return true;
    } else {
      return false;
    }
  }
}
