import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { UtilityService } from 'src/app/core/services/utility.service';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DeathNoticeTransactionWrapper } from 'src/app/core/global/interface/death-notice-transaction-wrapper.model';
import { ClaimsManagementConstants } from 'src/app/claims-management/constants/claims-management-constant';
import { ReviewAckOptions } from 'src/app/core/global/interface/death-notice-transaction-details-model';

@Component({
  selector: 'app-acknowledgement-section',
  templateUrl: './acknowledgement-section.component.html',
  styleUrls: ['./acknowledgement-section.component.scss']
})
export class AcknowledgementSectionComponent implements OnInit {

  constructor(
    private utilityService: UtilityService,
    private fb: FormBuilder
  ) {}

  public claimsManagementConstants= ClaimsManagementConstants;

  @Input() wrapper: DeathNoticeTransactionWrapper;
  @Input() contractOwnerRelationship: string;
  @Input() pageName = "Review";
  @Input() isPrintPage = false;
  
  @Output() backupWithholdingChangeEmitter = new EventEmitter();
  @Output() nraData = new EventEmitter();
  @Output() ackData = new EventEmitter();
  @Output() ssnData= new EventEmitter();

  public acknowledgementForm: FormGroup;
  public reviewAckOptions: ReviewAckOptions
  public countryOfResidence: string;

  public requiredNotices: boolean;
  public nraCheckboxChecked: boolean;
  public hyperlinkClicked: boolean;
  public showAgreementNoClickError: boolean;
  public validTIN_SSN: boolean;
  public inputSSNValid: boolean;
  
  ngOnInit(): void {
    this.initiateForm();
    this.requiredNotices = this.pageName === 'Confirmation' ? true : false; 

    if(this.wrapper && this.wrapper.transactionDetails 
      && this.wrapper.transactionDetails.length && this.wrapper.transactionDetails[0]?.reviewAckOptions){
      this.reviewAckOptions = this.wrapper.transactionDetails[0]?.reviewAckOptions[0];
    } 
    
  }

   /**
   * @method initiateForm
   * @description initiate reactive form 
   */
   initiateForm() {
    this.acknowledgementForm = this.fb.group({
        ssnTinCheck: new FormControl("", [Validators.required, Validators.pattern(/^[0-9]{9}$/), Validators.maxLength(9)]),
    });
}

  /**
   * @method checkValidSSN
   * @description Validation of SSN format and entered SSN
   */ 
  checkValidSSN() {
    this.validTIN_SSN = true;
    if (this.acknowledgementForm.controls.ssnTinCheck.valid) {
       this.inputSSNValid = true;
    } else {
        this.inputSSNValid = false;
    }

    if(this.inputSSNValid){
      if(!(this.acknowledgementForm.controls.ssnTinCheck.value === this.wrapper?.transactionDetails[0]?.unVerifiedBeneficiary[0]?.taxId)){
        this.validTIN_SSN= false;
      }
    
      this.ssnData.emit({validTIN_SSN : this.validTIN_SSN});
    }
  }

   /**
 * @method numerical
 * @description accept numerical value 
 */
   numerical(event) {
    return this.utilityService.numerical(event);
  }

  /**
 * @method subjectToBackupWithholdingChange
 * @param event 
 * @description On change of subjectToWithholding checkbox, disabling submit button 
 * and show error message
 */
  public subjectToBackupWithholdingChange(event) {
    this.backupWithholdingChangeEmitter.emit(event);
  }

   /**
   * @method nonResidentAlienChange
   * @param event 
   * @description On change of nonResidentAlien checkbox
   */
    public nonResidentAlienChange(event) {
      this.nraCheckboxChecked = event;
      this.nraData.emit({ nra: this.nraCheckboxChecked ? 'Y' : 'N', nraCountry: this.countryOfResidence });
    }

   /**
   * @method countryOfResidenceChange
   * @param event 
   * @description On change of countryOfResidence input field
   */
    public countryOfResidenceChange(event) {
      this.countryOfResidence = event;
      this.nraData.emit({ nra: this.nraCheckboxChecked ? 'Y' : 'N', nraCountry: this.countryOfResidence });
    }   
  
  /**
  * @method onChangeRequiredNotice
  * @description On change of Required Notice input field
  */
   public onChangeRequiredNotice() {       
    if(this.hyperlinkClicked)           
      this.ackData.emit({requiredNotices: this.requiredNotices }) 
    else
      this.showAgreementNoClickError = true;
  }   

  /**
  * @description marks a flag on opening of agreement link
  */
  public onAgreementLinkClick() {
    if (!this.hyperlinkClicked) {
      this.hyperlinkClicked = true;
      this.showAgreementNoClickError=false;
    }
  }

}
