import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TableCol } from '../../core/global/interface/table-col.interface';
import { Table } from "primeng/table";
import { SortEvent } from 'primeng/api/sortevent';
import { MenuItem } from 'primeng/api';
import { delay, of } from 'rxjs';
@Component({
  selector: 'app-claims-data-table',
  templateUrl: './claims-data-table.component.html',
  styleUrls: ['./claims-data-table.component.scss']
})
export class ClaimsDataTableComponent {
  @Input() cols: TableCol[];
  dataSourceArr: any[];
  first = 0;
  searchFilter: string;
  get dataSource() {
    return this.dataSourceArr;
  }
  @Input() set dataSource(dataSource) {
    this.dataSourceArr = dataSource;
  }

  @Input() autoLayout?: boolean;
  @Input() sortField?: string;
  @Input() sortOrder?: number;
  @Input() scrollable = false;
  @Input() tableWidth?: string;
  @Input() enableSort = true;
  @Input() rowHover = false;
  @Input() displayEmptyMessage = true;
  @Input() emptyMessage?: string;
  @Input() headerIcon = false;
  @Input() showHeaderOnEmptyData = false; // Optional param to display header incase of blank data array
  @Input() rows = 7;
  @Input() tableClass?: string;
  @Input() rowGroupMode?: string;
  @Input() groupRowsBy?: string;
  @Input() clickEnable: boolean = false;
  @Input() tableborder?: boolean;
  @Output() emitTable = new EventEmitter();
  // tslint:disable-next-line: whitespace
  @Output() errorTable?= new EventEmitter();
  @Output() emitActionTable = new EventEmitter();
  // tslint:disable-next-line: whitespace
  @Output() singleRowEmit = new EventEmitter();
  isMobileView: boolean;
  currentStateModel: any[];
  initSortField = of(true).pipe(delay(2000));
  @ViewChild("dt", { static: false }) public dt: Table;
  @Output() isMobileViewStatusEvent = new EventEmitter();
  @Input() paginator = true;
  @Input() paginationToTop = false;
  @Input() paginatorPosition?: string;
  @Input() paginatorLength?: number;
  @Input() scrollOnPagination = true;
  @Output() pageClicked = new EventEmitter();
  @Output() pageClickedCustomerContracts = new EventEmitter();
  @Output() pageTop = new EventEmitter();
  @Input() sectionId?: string;
  constructor(public breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    this.currentStateModel = [];
    this.breakPointObserver();
    this.sortField = this.sortField ? this.sortField : "";
    this.sortOrder = this.sortOrder ? this.sortOrder : 1;
    this.paginatorPosition = this.paginatorPosition ? this.paginatorPosition : "both";
    this.paginatorLength = this.paginatorLength ? this.paginatorLength : 7;
  }

  /**
   * @method eventEmit,emitSelecion,clearSelection
   * @description emit the data of selected row, field and its value or clear it's value
   * @param field
   * @param datarow
   * @param fieldValue
   */

  eventEmit(field, datarow, fieldValue, disabled = false) {
    if (!disabled) {
      const obj = {
        field,
        datarow,
        fieldValue
      };
      this.emitTable.emit(obj);
    }
  }

  /**
   * @method actionEmit
   * @description emit the data of selected row, field and its value
   * @param field
   * @param datarow
   * @param fieldValue
   */
  actionEmit(field, datarow, type, disabled = false) {
    if (!disabled) {
      const obj = {
        field,
        datarow,
        type
      };
      this.emitActionTable.emit(obj);
    }
  }
  /**
   * @method onRowSelect
   * @description Emit data on click of row
   */
  onRowSelect(data) {
    this.singleRowEmit.emit(data.data);
  }

   /**
   * @method onRowUnselect
   * @description Emit data on click of row
   */
  onRowUnselect(event){
    this.singleRowEmit.emit(null);
  }
  /**
   * @method breakPointObserver
   * @description To check device is mobile or laptop
   */
  breakPointObserver() {
    this.breakpointObserver
      .observe(["(min-width: 1025px)"])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileView = false;
        } else {
          this.isMobileView = true;
          this.tableWidth = "100%";
        }
        this.isMobileViewStatusEvent.emit(this.isMobileView);
      });
  }

    /**
   * @method onPaginate
   * @description listener for on click of pagination
   */
     onPaginate(event) {
      if (this.scrollOnPagination === true) {
        window.scroll(0, 100);
      }
      this.pageClicked.emit(true);
      //S-08961 added to raise paginate event which can be listened in multiple contracts agent data table
      if (this.dt) {
        const eventData = {
          first: this.dt._first,
          last: this.dt._first + this.dt._rows,
          filteredRows: this.dt.filteredValue,
          eventSource: 'paginate'
        }
        this.pageClickedCustomerContracts.emit(eventData);
      }
    }

    scrollToTop(event: any) {
      if (this.paginationToTop === true) {
        const element = document.getElementById(this.sectionId);
        this.pageTop.emit(element.scrollIntoView({ block: "start" }));
      }
    }
  rowEmit(datarow) {
    this.emitTable.emit(datarow);
  }
}
