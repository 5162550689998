import { PipeTransform, Pipe } from "@angular/core";
@Pipe({
  name: "uscode",
})
export class USCode implements PipeTransform {
  transform(value: string): string {
    if (value && value.length > 5) {
      if (value.length === 6 && value.match('-')) {
        return value.slice(0, 5);
      }
      const zip = value.replace("-", "");
      return [zip.slice(0, 5), "-", zip.slice(5)].join('');
    } else {
      return value;
    }
  }
}
