import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../../services/loader.service';

type loaderObj = {
  show: boolean;
  msg: string;
}
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  loadText: string = "";
  showLoader: boolean = false;
  isLoading: Subject<loaderObj> = this.loaderService.isLoading;
  constructor(private loaderService: LoaderService) {
  }
  ngOnInit() {
    this.loaderService.isLoading.subscribe(response => {
      if (response) {
        if(response.msg){
          this.loadText = response.msg;
        } else {
          this.loadText = "";
        }
        this.showLoader = response.show;
        if(response.show){
          document.body.style.overflowY = "hidden";
        } else {
          document.body.style.overflowY = "scroll";
        }
      } else {
        document.body.style.overflowY = "scroll";
      }
    });

  }
}
