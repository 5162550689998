import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LayoutModule } from "@angular/cdk/layout";
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { OktaConfig } from './core/global/config/okta-config';
import { UtilityService } from './core/services/utility.service';
import { PlatformLocation } from '@angular/common';
import { ApiRetryDelayInterceptor } from './core/services/interceptors/api-retry-delay.interceptor';
import { AppConfig } from './core/global/config/app-config';
import { SharedModule } from './shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { ProxyComponent } from './proxy.component';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    ProxyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OktaAuthModule,
    LayoutModule,
    NgIdleKeepaliveModule.forRoot(),
    BackButtonDisableModule.forRoot({
      preserveScroll: true
    }),
    SharedModule,
    ButtonModule,
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRetryDelayInterceptor,
      multi: true
    },
    {
      provide: OKTA_CONFIG,
      useFactory: OktaConfig,
      deps: [UtilityService, PlatformLocation]
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
