import { PrintComponentModel } from "../core/global/interface/print-component-model";
import { DeathNoticeConfirmationComponent } from "../public/death-notice-confirmation/death-notice-confirmation.component";

export class PrintComponent{
    items:PrintComponentModel[] = [
        {
          name: 'deathNoticeConfirm',
          component: DeathNoticeConfirmationComponent
        }
    
        ]
}
