import { ErrorHandler, NgModule } from "@angular/core";
import { LoaderComponent } from "./core-components/loader/loader.component";
import { AjaxServices } from "./services/ajax-services/ajax-service.service";
import { AjaxConfigService } from "./services/ajax-services/ajax-config.service";
import { ScrollTopComponent } from "./core-components/scroll-top/scroll-top.component";
import { AuthGuard } from "./services/guards/auth-guard.service";
import { UtilityService } from "./services/utility.service";
import { LoaderService } from "./services/loader.service";
import { ErrorService } from "./services/error.service";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AppConfig } from "./global/config/app-config";
import { GlobalErrorHandler } from "./services/global-error-handler";
import { InternalLoadGuard } from "./services/guards/internal-load.guard";
import { PublicGuard } from "./services/guards/public.guard";

// export function initializeApp(appConfig: AppConfig) {
//   return () => appConfig.load();
// }
@NgModule({
  providers: [
    ErrorService,
    AjaxServices,
    AjaxConfigService,
    UtilityService,
    LoaderService,
    AuthGuard,
    InternalLoadGuard,
    PublicGuard,
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  declarations: [ LoaderComponent, ScrollTopComponent],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule
  ],

  exports: [LoaderComponent, ScrollTopComponent],
})
export class CoreModule { }
