import { Component, EventEmitter,Input, Output } from '@angular/core';
import { ClaimsManagementConstants } from '../../claims-management/constants/claims-management-constant';
import { ReviewAckOptions } from 'src/app/core/global/interface/death-notice-transaction-details-model';

@Component({
  selector: 'app-non-resident-alien',
  templateUrl: './non-resident-alien.component.html',
  styleUrls: ['./non-resident-alien.component.scss']
})
export class NonResidentAlienComponent {

  public claimsManagementConstants= ClaimsManagementConstants;
  nonResidentAlienChecked = false;
  countryOfResidence: string;
  nraOptionsValue: ReviewAckOptions;

  @Input() pageName: string;

  get reviewAckOptions() {
    return this.nraOptionsValue;
  }
  @Input() set reviewAckOptions(value) {
    if(value) {
      this.nraOptionsValue = value;
      this.updateFields(this.nraOptionsValue);
    }
  }
  @Output() nonResidentAlienChange = new EventEmitter<boolean>();
  @Output() countryOfResidenceChange = new EventEmitter<string>();

  updateFields(nraOptions: ReviewAckOptions) {
    if (nraOptions) {
      if (nraOptions.nra && nraOptions.nra.toLocaleLowerCase() === 'y') {
        this.nonResidentAlienChecked = true;
      } else {
        this.nonResidentAlienChecked = false;
      }

      if (nraOptions.nraCountry) {
        this.countryOfResidence = nraOptions.nraCountry;
      } else {
        this.countryOfResidence = null;
      }
    }
  }
  /**
   * @method OnChangeNonResidentAlien
   * @param event 
   * @description On change of NonResidentAlien checkbox emit the event
   */
  onChangeNonResidentAlien() {
    //If Checkbox is unticked remove NRA country values
    if(!this.nonResidentAlienChecked){
      this.countryOfResidence=null;
      this.onChangeCountryOfResidence();
    }
    this.nonResidentAlienChange.emit(this.nonResidentAlienChecked);
  }

  /**
  * @method onChangeCountryOfResidence
  * @param none 
  * @description On change of countryofresidence input field emit the event
  */
  onChangeCountryOfResidence() {
   this.countryOfResidenceChange.emit(this.countryOfResidence);
  }

}
