<p-calendar [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [ngModel]="dateRange" (ngModelChange)="onChange($event)" [showIcon]="true" [minDate]="minDate"
    [maxDate]="maxDate" [selectionMode]="selectionMode" [readonlyInput]="true" [showButtonBar]="showButtonBar" [disabledDates]="disabledDateList"
    [disabledDays]="disabledWeekDays" (onClearClick)="clearButtonEvent()" (onShow)="onCalendarPanelShow()" (onMonthChange)="monthChangeEvent()"
    (onClose)="closeEvent()" [disabled]="disabled" [placeholder]="placeholder" [baseZIndex]="baseZIndex">
    <!-- to remove the other month dates from current view -->
    <ng-template pTemplate="date" let-date>
        <span *ngIf="!date.otherMonth">{{checkDate(date)}}</span>
        <span *ngIf="date.otherMonth" class="otheMonthDate"></span>

    </ng-template>
</p-calendar>