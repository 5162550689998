import { Component, OnInit } from '@angular/core';
import { LabelsConstants } from 'src/app/core/global/constants/labels-constant';
import { StepperConstants } from 'src/app/core/global/constants/stepper-constants';
import { UtilityService } from 'src/app/core/services/utility.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DisclosureModel } from 'src/app/core/global/interface/disclosure.model';
import { PublicService } from 'src/app/core/services/public.service';
import { take } from 'rxjs';
import { ReviewAckOptions } from 'src/app/core/global/interface/death-notice-transaction-details-model';
import { DeathNoticeTransactionWrapper } from 'src/app/core/global/interface/death-notice-transaction-wrapper.model';

@Component({
  selector: 'app-death-notice-confirmation',
  templateUrl: './death-notice-confirmation.component.html',
  styleUrls: ['./death-notice-confirmation.component.scss'],
  providers:[DynamicDialogConfig,DynamicDialogRef]
})
export class DeathNoticeConfirmationComponent implements OnInit{
  public activeAction: string = StepperConstants.CONFIRMATION;
  public completedActions: string[] = [StepperConstants.INITIATE, StepperConstants.REVIEWANDACKNOWLEDGE];
  public pendingActions: string[] = [];
  public contractOwnerRelationship = this.utilityService.getSessionStorageData('contractOwnerRelationship');
  labelConstants = LabelsConstants;
  public isPrint = this.utilityService.getIsPrint();
  public dialogComponentRef = this.dialogService.dialogComponentRefMap;
  public disclosure: DisclosureModel;
  public reviewAckOptions: ReviewAckOptions;
  public wrapper: DeathNoticeTransactionWrapper = this.utilityService.getSessionStorageData('transactionWrapper');
  uploadedDocuments = this.wrapper.transactionDetails[0]?.documents || [];
  constructor(
    private utilityService:UtilityService,
    private dialogService: DialogService,
    private publicService: PublicService
  ){}
  ngOnInit(): void {
    window.scroll(0,0);
    if(this.isPrint){
      this.publicService.getDisclosure(this.wrapper?.transactionRequest?.policyNumber).pipe(take(1)).subscribe((data:DisclosureModel) => {
        this.disclosure = data;
      });
    }

    if(this.wrapper && this.wrapper.transactionDetails 
      && this.wrapper.transactionDetails.length && this.wrapper.transactionDetails[0]?.reviewAckOptions){
      this.reviewAckOptions = this.wrapper.transactionDetails[0]?.reviewAckOptions[0];
    }
  }
 
  goToFaq(){
    this.utilityService.goToFAQ();
  }

  // method to show preview dialog for printing
  printTransactionConfirmation(){
    this.utilityService.printTransactionConfirmation(this.dialogService);
    if (window.matchMedia) {
      //  storing the value of mediaQueryList when media query is print i.e when we're printing
      const mediaQueryList = window.matchMedia('print');

      mediaQueryList.addEventListener("change",(mql)=> {
        // mql.matches === true if mediaQueryList is print
          if (mql.matches) {
              this.onWindowBeforePrint();
          } else {
              this.onWindowAfterPrint();
          }
      });
  }
  //before print when beforeprint event occurs call onWindowBeforePrint
  window.onbeforeprint = this.onWindowBeforePrint.bind(this);
   //after print when afterprint event occurs call onWindowBeforePrint
  window.onafterprint = this.onWindowAfterPrint.bind(this);
   }

  onWindowAfterPrint() {        
    const printSection = document.getElementById("printSectionclaimsexternal");
  // clearing the element that was added to DOM with id printSectionclaimsexternal
    if (printSection) {
        printSection.innerHTML = "";
    }
    this.utilityService.printAppended = false;
}

onWindowBeforePrint() {
    this.utilityService.appendPrintSection();
}

}
