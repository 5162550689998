import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from "@okta/okta-angular";
import { OktaAuth } from "@okta/okta-auth-js";
import { map, Observable, of } from 'rxjs';
import { ServiceUrlConstants } from '../global/constants/serviceurl-constants';
import { AjaxServices } from './ajax-services/ajax-service.service';
import { UtilityService } from './utility.service';
import { Router } from '@angular/router';
import { DecedentInfo } from 'src/app/claims-management/models/decedent-info-model';
import { PublicService } from './public.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    isOKTAAuthenticated: boolean;
    public origin = window.location.origin;
    public isFeatureEnabled = false;
    constructor(
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
        private http: AjaxServices,
        private utilityService: UtilityService,
        private router: Router,
        public publicService: PublicService) { }

    async isCSRAuthenticated() {
        this.isOKTAAuthenticated = await this.oktaAuth.isAuthenticated();
        return this.isOKTAAuthenticated;

    }

    logout(): any {
        this.utilityService.resetUserProfileData();
        return this.http.getApi(ServiceUrlConstants.USER_LOGOUT, {
            withCredentials: true,
            responseType: 'text' as 'json'
        });
    }

    /**
     * @description indirectly logs out parent window by adding one storage item.
     */
    public logoutParent() {
        window.localStorage.setItem('loggedOut', 'loggedOut');
        window.localStorage.removeItem('loggedOut');
    }

    /**
     * @description get public access key for public read view
     */
    public getpublicAccess(): Observable<{enableDeathNotification: string}> {
        return this.http.getTokenApi(ServiceUrlConstants.GET_PUBLIC_ACCESS ,true ,false);
    }

  

    /**
     * @description checks for write access
     */
    private checkForWriteAccess(ssn, resolve): void {
        const accessToken = sessionStorage.getItem('accessToken');
        if (accessToken) {
            this.utilityService.setTokenInfo({ token: accessToken });
            resolve(true);
        } else {
            const encryptedSSN = this.utilityService.encryptAndEncodeData(ssn);
            this.publicService.validateInfo(encryptedSSN).subscribe(
                (data) => {
                    if (data) {
                        // this.loaderService.showLoader(); add loader here
                     resolve(true);
                    }
                }
            )
        }
        // return new Promise<any>(async (resolve, reject) => {
        // })
    }
    /**
     * @description figure out if use requires erad or write access
     */
    public checkForAccess(): Promise<boolean> {
        return new Promise<boolean>( (resolve, reject) => { 
            const decedentData: DecedentInfo =  this.utilityService.getSessionStorageData("decedentData");
            if(decedentData && decedentData.ssn) {
                return this.checkForWriteAccess(decedentData.ssn, resolve);
            }
        })
    }


}
