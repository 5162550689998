import { HttpClient, HttpBackend, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, of, throwError, timeoutWith } from "rxjs";
import { ServiceUrl } from "../service-url.model";
import { OktaAuthStateService } from "@okta/okta-angular";
import { AjaxConfigService } from "./ajax-config.service";
import { UtilityService } from "../utility.service";
import { LoaderService } from "../loader.service";
import { SettingsConstants } from "../../global/constants/settings-constants";
import { ErrorService } from "../error.service";
import { ServiceUrlConstants } from "../../global/constants/serviceurl-constants";
import { LogData } from "../logger/log-data.model";
import { ValidateInfoPayload } from "../../global/interface/validate-info-payload.model";
import { HttpOptions } from "../../global/interface/http-option-model";

@Injectable()
export class AjaxServices extends AjaxConfigService {

  public isAuthTokenValid = false;
  private errorHttpClient: HttpClient;

  constructor(
    private http: HttpClient,
    handler: HttpBackend,
    protected oktaAuth: OktaAuthStateService,
    protected utilityService: UtilityService,
    private loaderService: LoaderService,
    private errorService: ErrorService,
    private utilService:UtilityService
  ) {
    super(oktaAuth, utilityService);
    this.errorHttpClient = new HttpClient(handler);
  }

  /**
  * @method getTokenApi
  * @description it will call the pi rquired to get the read token
  * @param serviceUrlObj is a object of type ServiceUrl
  * @param isSpinnerNeeded option to specify the spinner is needed for the api or not
  * @param requiresRead does this require a read or a write token
  */
  getTokenApi(serviceUrlObj: ServiceUrl, isSpinnerNeeded = true, requiresRead = false) {
    if (!navigator.onLine) {
      this.errorService.showError("No Internet Connection");
      return of(null);
    }
    if (!this.currentServiceObj) {
      super.fetchServiceHost();
    }
    if (isSpinnerNeeded) {
      this.requestInProgress++;
    }
    const url = super.getURL(serviceUrlObj);
    let httpOptions;
    if (!requiresRead) {
      httpOptions = {
        observe: 'response' as const
      };
    } else {
      const headers = super.getHeader(serviceUrlObj);
      if (headers) {
        httpOptions = {
          headers,
          observe: 'response' as const,
          withCredentials: true
        }
        httpOptions = {...httpOptions};
      }
    }

    if (isSpinnerNeeded) {
      if (this.requestInProgress === 1) {
        this.loaderService.showLoader();
      }
    }

    return this.http.get(url, httpOptions).pipe(
      timeoutWith(SettingsConstants.ServiceTimeout * 1000, throwError(new Error('HTTP Timeout exceeds for ' + url))),
      map((response: any) => {
        const token = response?.headers.get('Access');
        if (token) {
          this.utilityService.setTokenInfo({ token });
          if (!requiresRead) {            
            sessionStorage.setItem('readToken', token);
          } else {
            sessionStorage.setItem('accessToken', token);
          }
          return this.handleSuccess(response.body, serviceUrlObj, isSpinnerNeeded)
        } else {
          if(requiresRead) {
            return this.handleSuccess(response.body, serviceUrlObj, isSpinnerNeeded)
          } else {
            return throwError(() => new Error('Invalid token'))
          }
        }
      }
      ),
      catchError((error: HttpErrorResponse) => this.handleError(error, serviceUrlObj))
    );


  }


  /**
 * @method getApi
 * @description it will create full service url and do http get to that url
 * @param serviceUrlObj is a object of type ServiceUrl
 * @param opt http get options
 * @param param extra data queryparam the api
 * @param isSpinnerNeeded option to specify the spinner is needed for the api or not
 * @param isReadTokenRequired option to use read Token
 **/
  getApi(
    serviceUrlObj: ServiceUrl,
    opt = null,
    param: any = null,
    isSpinnerNeeded = true,
    isReadTokenRequired = false
  ): Observable<any> {
    if (!navigator.onLine) {
      this.errorService.showError("No Internet Connection");
      return of(null);
    }
    if (!this.currentServiceObj) {
      super.fetchServiceHost();
    }
    if (isSpinnerNeeded) {
      this.requestInProgress++;
    }
    const url = super.getURL(serviceUrlObj);
    let headers;
    if(!isReadTokenRequired) {
      headers = super.getHeader(serviceUrlObj);
    } else {
      headers = super.getReadHeader(serviceUrlObj);
    }
    if (headers) {
      let httpOptions = {
        headers,
        observe: "body" as any,
        params: param,
        withCredentials: true
      };
      if (opt) {
        httpOptions = { ...httpOptions, ...opt };
      }
      if (isSpinnerNeeded) {
        if (this.requestInProgress === 1) {
          this.loaderService.showLoader();
        }
      }

      return this.http.get(url, httpOptions).pipe(
        timeoutWith(SettingsConstants.ServiceTimeout * 1000, throwError(new Error('HTTP Timeout exceeds for ' + url))),
        map(response => {
          return this.handleSuccess(response, serviceUrlObj, isSpinnerNeeded)
        }
        ),
        catchError((error: HttpErrorResponse) => this.handleError(error, serviceUrlObj))
      );
    }
  }

  /**
 * @method postApi
 * @description it will create full service url and post data to that url
 * @param serviceUrlObj is a object of type ServiceUrl
 * @param data post data
 * @param opt http post options
 * @param isSpinnerNeeded option to specify the spinner is needed for the api or not
 */
  postApi(
    serviceUrlObj: ServiceUrl,
    data: any,
    opt = null,
    isSpinnerNeeded = false
  ): Observable<any> {
    if (!navigator.onLine) {
      this.errorService.showError("No Internet Connection");
      return of(null);
    }
    if (!this.currentServiceObj) {
      super.fetchServiceHost();
    }
    const url = super.getURL(serviceUrlObj);
    const headers = super.getHeader(serviceUrlObj);
    if (headers) {
      if (isSpinnerNeeded) {
        this.requestInProgress++;
      }
      let httpOptions = {
        headers,
        observe: "body" as any,
        params: null,
        withCredentials: true
      };
      if (opt) {
        httpOptions = { ...httpOptions, ...opt };
      }
      if (isSpinnerNeeded) {
        if (this.requestInProgress === 1) {
          this.loaderService.showLoader();
        }
      }
      return this.http.post(url, data, httpOptions).pipe(
        timeoutWith(SettingsConstants.ServiceTimeout * 1000, throwError(new Error('HTTP Timeout exceeds for ' + url))),
        map(response =>
          this.handleSuccess(response, serviceUrlObj, isSpinnerNeeded)
        ),
        catchError((error: HttpErrorResponse) => this.handleError(error, serviceUrlObj))
      );
    }
  }
  handleSuccess(response, urlObj, isSpinnerNeeded) {
    if (this.requestInProgress > 0) {
      this.requestInProgress--;
    }
    if (this.requestInProgress === 0) {
      this.loaderService.hideLoader();
    }

    if (response || response === false) {
      return response;
    }
    return true;
  }

  handleError(error, urlObj) {
    this.requestInProgress = 0;
    this.loaderService.hideLoader();
    const ignoreList = ['logoutService/logout'];
    this.errorService.handleAPIError(error);
    console.warn(error)
    // if urlobj.url has the content which is not inside this ignorelist then logmessage
    if (!ignoreList.includes(urlObj.endPointUrl)) {
      this.logMessage(error, 'ERROR');
    }
    
    return error.error;
  }

  /**
 * It will directly post logdata to server by compairing logdata.logLevel with serverLogLevel
 * @param logdata - LogData type object
 */
  logMessage(errorData: any, logLevel = 'ERROR') {
    const logdata = this.logError(errorData, '', logLevel);
    if (SettingsConstants && logdata) {
      this
        .postError(
          ServiceUrlConstants.API_SERVER_SIDE_LOGGING,
          this.prepareData(logdata)
        )
        .subscribe();
    }
  }


  /**
   * @method postError
   * @description it will create full service url and post data to that url using errorHttpClient which will not be intercepted.
   * @param serviceUrlObj is a object of type ServiceUrl
   * @param data post data
   * @param opt http post options
   */
  postError(serviceUrlObj: ServiceUrl, data: any, opt = null): Observable<any> {
    if (!navigator.onLine) {
      this.errorService.showError('No Internet Connection');
      return of(null);
    }
    if (!this.currentServiceObj) {
      super.fetchServiceHost();
    }
    const url = super.getURL(serviceUrlObj);
    const headers = super.getHeader(serviceUrlObj);
    let httpOptions = {
      headers,
      observe: 'body' as any,
      params: null,
      withCredentials: true
    };
    if (opt) {
      httpOptions = { ...httpOptions, ...opt };
    }
    return this.errorHttpClient.post(url, data, httpOptions).pipe(
      timeoutWith(SettingsConstants.ServiceTimeout * 1000, throwError(new Error('HTTP Timeout exceeds for ' + url))),
      map(response => {
        //     this.loaderService.hideLoader();
        return response;
      }),
      catchError((error: HttpErrorResponse) => this.handleLogError(error, serviceUrlObj))
    );
  }
  handleLogError(error, urlObj) {
    this.loaderService.hideLoader();
    return error;
  }
  /**
  * It will map logdata to expected request format.
  * @param logdata LogData object
  */
  prepareData(logdata: LogData): any {
    const messageObj = JSON.parse(logdata.logInfo);
    const logLevel = logdata.logLevel;
    const currentBrowserObj = this.utilityService.currentSystemDetails;
    const body = {
      batchSequenceID: '',
      events: [
        {
          level: logLevel,
          location: window.location.origin + messageObj.url,
          message: messageObj.message,
          requestID: messageObj.user ? messageObj.user : '',
          stack: messageObj.stack,
          timeMillis: new Date().toLocaleString('en-US', { timeZone: 'America/New_york' }),
          url: messageObj.url,
          userAgent: Object.keys(currentBrowserObj).map(key => `${key}: ${currentBrowserObj[key]};`).join(' '),
          prvUrl: ''
        }
      ],
      sessionID: '',
      userID: messageObj.user ? messageObj.user : ''
    };
    return body;
  }
  
    /**
  * @method postTokenApi
  * @description it will call the Api rquired to get the read token,this method is exclusive for validateinfo as it requires readtoken
  * @param serviceUrlObj is a object of type ServiceUrl
  * @param isSpinnerNeeded option to specify the spinner is needed for the api or not
  */
  // 
  postTokenApi(
    serviceUrlObj: ServiceUrl,
    data: ValidateInfoPayload,
    opt = null,
    isSpinnerNeeded = false,
  ){
    if (!navigator.onLine) {
      this.errorService.showError("No Internet Connection");
      return of(null);
    }
    if (!this.currentServiceObj) {
      super.fetchServiceHost();
    }
    const url = super.getURL(serviceUrlObj);
    let httpOptions:HttpOptions;
      const headers = super.getReadHeader(serviceUrlObj);
      if (headers) {
        httpOptions = {
          headers,
          observe: 'response',
          withCredentials: true
        }
        httpOptions = {...httpOptions};
      }
    
      if (isSpinnerNeeded) {
        this.requestInProgress++;
      }
      if (opt) {
        httpOptions = { ...httpOptions, ...opt };
      }
      if (isSpinnerNeeded) {
        if (this.requestInProgress === 1) {
          this.loaderService.showLoader();
        }
      }
      return this.http.post(url, data, httpOptions).pipe(
        timeoutWith(SettingsConstants.ServiceTimeout * 1000, throwError(new Error('HTTP Timeout exceeds for ' + url))),
        map((response:any) =>{
          const token = response?.headers?.get('Access') as string;
          if(token){
            sessionStorage.setItem('accessToken', token);
            this.utilityService.setTokenInfo({ token });
          }         
          return this.handleSuccess(response?.body, serviceUrlObj, isSpinnerNeeded)
        }
        ),
        catchError((error: HttpErrorResponse) => this.handleError(error, serviceUrlObj))
      );
    }
}

