
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ErrorLog } from '../../global/constants/error-const';
import { OktaAuthStateService } from "@okta/okta-angular";
import { LogData } from '../logger/log-data.model';
import { ServiceUrl } from "../service-url.model";
import { UtilityService } from "../utility.service";
@Injectable()
export class AjaxConfigService {
  protected currentServiceObj = null;
  protected requestInProgress = 0;
  constructor(
    protected oktaAuth: OktaAuthStateService,
    protected utilityService: UtilityService
  ) {
    this.fetchServiceHost();
  }

  /**
   * @method fetchServiceHost
   * @description It will get environment information by checking window location
   */
  public fetchServiceHost() {
    this.utilityService.fetchServiceHost();
    this.currentServiceObj = this.utilityService.fetchCurrentServiceObj();
  }

  /**
   * @method getURL
   * @description it will check for user type and service type and then return corresponding service url of requestid
   * @param serviceUrlObj is a ServiceUrl type object
   */
  protected getURL(serviceUrlObj: ServiceUrl): string {
    // if CSR user trying to access Internal API resources then use Internal stage name or else use external stage name
    if (serviceUrlObj.isInternal) {
      return (
        `${this.currentServiceObj.serviceHost}${this.currentServiceObj.integraionNameInternal}` +
        `${this.currentServiceObj.internalBasePath}${serviceUrlObj.endPointUrl}`
      );
    } else if (serviceUrlObj.isPublic) {
      return (
        `${this.currentServiceObj.serviceHost}${this.currentServiceObj.integrationNameExternal}` +
        `${this.currentServiceObj.externalBasePath}${this.currentServiceObj.integrationNamePublic}${serviceUrlObj.endPointUrl}`
      );
    }
    return (
      `${this.currentServiceObj.serviceHost}` +
      `${this.currentServiceObj.integrationNameExternal}${this.currentServiceObj.externalBasePath}${serviceUrlObj.endPointUrl}`
    );
  }

  /**
   * @name AjaxConfigService#isProtected
   * @methodOf AjaxConfigService
   * @description Used to get web service is protected or not. i.e can be accessed before login or not
   * @param serviceUrlObj is a ServiceUrl type object
   */
  protected isProtected(serviceUrlObj: ServiceUrl): boolean {
    return serviceUrlObj.isProtected;
  }

  /**
   * @name AjaxConfigService#getHeader
   * @methodOf AjaxConfigService
   * @description Used to get web service header
   * @param serviceUrlObj is a ServiceUrl type object
   */
  protected getHeader(serviceUrlObj: ServiceUrl) {
    if (this.utilityService.isCsr) {
      return this.csrHeader(serviceUrlObj);
    } else {
      return this.nonCsrHeader(serviceUrlObj);
    }
  }

  /**
   * @name AjaxConfigService#getReadHeader
   * @methodOf AjaxConfigService
   * @description Used to get web service read only header
   * @param serviceUrlObj is a ServiceUrl type object
   */
  protected getReadHeader(serviceUrlObj: ServiceUrl) {
    if (this.utilityService.isCsr) {
      return this.csrHeader(serviceUrlObj, true);
    } else {
      return this.nonCsrHeader(serviceUrlObj, true);
    }
  }

  protected csrHeader(serviceUrlObj: ServiceUrl, readTokenRequired = false) {
    const tokenStorage = JSON.parse(
      sessionStorage.getItem("okta-token-storage")
    );
    const claimstokenStorage = sessionStorage.getItem("claims-access-token-storage");
    const accessToken = tokenStorage ? (tokenStorage.accessToken ? tokenStorage.accessToken.accessToken : "") : "";
    const xApiKey = "NA";
    //S-23502 - changes to use access token(okta / claims) based on type of api call
    if (serviceUrlObj.endPointUrl === "getclaimsaccess") {
      const headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", "Bearer " + accessToken);
      return headers;
    } else {
      const headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", "Bearer " + claimstokenStorage);
      return headers;
    }
  }

  /**
   * @method nonCsrHeader
   * @description It will header if logged in user is not CSR
   */
  protected nonCsrHeader(serviceUrlObj: ServiceUrl, readTokenRequired = false) {
    const tokenStorage = this.utilityService.getTokenInfo() ? { ...this.utilityService.getTokenInfo() } : {};
    const readToken = sessionStorage.getItem('readToken');
    if (readTokenRequired && readToken) {
      tokenStorage.token = readToken;
    }
    const xApiKey = this.currentServiceObj.tokenExternal || '';
    if (!serviceUrlObj.isProtected) {
      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        "x-api-key": xApiKey
      });
      return headers;
    }
    if (tokenStorage && tokenStorage.token ) {
      let headers;
      // checking for bypassContentType flag to avoid adding content type in case of multipart/form-data for boundry misssing in header
      if(serviceUrlObj.bypassContentType){
        headers = new HttpHeaders({
         "x-api-key": xApiKey,
         "Authorization": "Bearer " + tokenStorage.token
       });
     }else{
       headers = new HttpHeaders({
        "Content-Type": "application/json",
        "x-api-key": xApiKey,
        "Authorization": "Bearer " + tokenStorage.token
      });
     } 
     return headers;
    } else {
      if (serviceUrlObj.isProtected) {
        return null;
      } else {
        const headers = new HttpHeaders({
          "Content-Type": "application/json",
          "x-api-key": xApiKey,
        });
        return headers;
      }
    }
  }

  protected logError(error: any, bodyData = '', logLevel = 'ERROR') {
    const serverLogLevel = UtilityService.serverLogLevel;
    if (ErrorLog[logLevel] >= serverLogLevel) {
      const errorToSend = this.addContextInfo(error, bodyData);
      const errorData: LogData = {
        logInfo: JSON.stringify(errorToSend),
        logLevel
      };
      errorData.logInfo = JSON.stringify(errorToSend);
      errorData.logLevel = logLevel;
      return errorData;
    } else {
      return null;
    }
  }

  private addContextInfo(error: any, bodyData = '') {
    const name = error.name || null;
    const url = window.location.pathname;
    let user;
    if (this.utilityService.isCsr) {
      const csrData = this.utilityService.getCsrUserData();
      if (url.includes('/claims')) {
        user = csrData && csrData.email ? csrData.email : '';
      }
    } else {
      const userInfo: any = this.utilityService.getTokenInfo();
      user = userInfo ? userInfo.userId : '';
    }
    const stack = error instanceof HttpErrorResponse ?
      (error.error && error.error.error && error.error.error.debugMessage) ?
        error.error.error.debugMessage : null : error.stack;
    const message = error && (error.error && error.error.error && error.error.error.message)
      ? error.error.error.message + `${' body : ' + JSON.stringify(bodyData)}`
      : ((error && error.message) ? ((error.message).toString() + `${' body : ' + JSON.stringify(bodyData)}`) :
        error.toString() + `${' body : ' + JSON.stringify(bodyData)}`);

    const errorWithContext = {
      name,
      user,
      url,
      status,
      message,
      stack,
    };
    return errorWithContext;
  }
}
