import { Component, OnInit, Type } from '@angular/core';
import { DeathNoticeConfirmationComponent } from '../death-notice-confirmation/death-notice-confirmation.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PrintComponent } from 'src/app/shared/printer-components';
import { PrintComponentModel } from 'src/app/core/global/interface/print-component-model';

@Component({
  selector: 'app-printer-page',
  templateUrl: './printer-page.component.html',
  styleUrls: ['./printer-page.component.scss'],
  providers: [PrintComponent]
})
export class PrinterPageComponent {
  constructor(private dialogService:DialogService,private config: DynamicDialogConfig, private printComp:PrintComponent){
    // printComp is an array contains list of eligible component which can be printed
  }
  public currentComponent = this.printComp.items.filter((item:PrintComponentModel)=>{
    // To print a component add that component class with key in printComp array and filter it here
    if(item.name === 'deathNoticeConfirm'){
      return item.component
    }
   })[0]?.component || null;
  public dialogComponentRef = this.dialogService.dialogComponentRefMap;

}
