import { IAppConfig } from "../config/app-config.model";

export const SettingsConstants: IAppConfig = {
  logging: {
    consoleLogLevel: 2,
    serverLogLevel: 3,
    endPointConfig: {
      logAPI: "api/Logger/LogException"
    }
  },
  venportalServiceURL: "",
  ServiceRetryCount: 2,
  ServiceRetryDelay: 3,
  ServiceTimeout: 120
};
