<div class="pt-1">
    <div class="col-12 row pb-1 px-0">
        <span class="pe-0 ps-0 fw-bold">
            {{claimsManagementConstants.NON_RESIDENT_ALIEN_TITLE}}
        </span>
    </div>
    <div class="col-12 row pb-1 ps-2">
        <span class="pe-0 ps-2">
            {{claimsManagementConstants.NON_RESIDENT_ALIEN_CONTENT_TITLE}}
        </span>
    </div>

   <div class="col-12 row pb-1 ps-2">
        <div class="pe-0 ps-2">
            <div class="col-lg-12 d-flex align-items-center">
                <p-checkbox id="nonResidentAlienCheck" binary="true" name="nonResidentAlienCheck" [(ngModel)]="nonResidentAlienChecked"
                 (onChange)="onChangeNonResidentAlien()" [disabled]="pageName ==='Confirmation'">
                </p-checkbox>
                <span class="ps-2">
                 {{claimsManagementConstants.NON_RESIDENT_ALIEN_POINT_1}}
                </span>
            
           <span class="ps-2">
                <input type="text" pInputText pKeyFilter="alpha" name="countryOfResidence"
                    [(ngModel)]="countryOfResidence" 
                    [disabled]="!nonResidentAlienChecked || (pageName ==='Confirmation')"
                    (input)="onChangeCountryOfResidence()" required />
            </span>
        </div> 
        </div>
    </div>
    <div class="col-12 row pb-1 ps-2">
        <span class="pe-0 ps-2">
            {{claimsManagementConstants.NON_RESIDENT_ALIEN_POINT_2}}
        </span>
    </div>
</div>