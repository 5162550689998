<div class="mt-4 mb-1 ">
    <span class="fw-color">Decedent Information</span>
</div>
<div class="collapsable-div box-shadow-remove" 
[ngClass]="{'bottom-margin': isCollapsed  }">
    <p-panel #pnl [header]="decedentName" [toggleable]="isToggleable" expandIcon="" collapseIcon="" [collapsed]="isCollapsed"
        styleClass="custom-title-panel1 custom-content-panel2 custom-icon custom-icon-focus "
        (collapsedChange)="toggleChange($event)">
        <p-header>
            <a *ngIf="isToggleable" class="toggle-custom-icon" (click)="pnl.toggle($event)">
                <span *ngIf="pnl.collapsed" class="pi pi-plus ven-pitext pt-1"></span>
                <span *ngIf="!pnl.collapsed" class="pi pi-times ven-pitext pt-1"></span>
            </a>
        </p-header>
        <div class="row collapse-data" >
            <div class="col-sm-12 col-md-3 ad-padding-adjuster col-lg-3">
                <span class="venheader-titles-font">
                    Date of Birth
                </span>
                <div class="child-after-header">
                    <span>{{decedentInfo.dob | mask: "dob"}} </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-3 ad-padding-adjuster col-lg-3">
                <span class="venheader-titles-font">
                    Date of Death
                </span>
                <div class="child-after-header">
                    <span>{{decedentInfo.dateOfDeath}} </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-3 ad-padding-adjuster col-lg-3">
                <span class="venheader-titles-font">
                    Social Security Number
                </span>
                <div class="child-after-header">
                    <span>{{decedentInfo.ssn | mask:'ssn'}} </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-3 ad-padding-adjuster col-lg-3">
                <span class="venheader-titles-font">
                    State of Residence
                </span>
                <div class="child-after-header">
                    <span> {{decedentInfo.stateOfResidence}} </span>
                </div>
            </div>
        </div>
    </p-panel>
</div>