import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


type loaderObj = {
  show: boolean;
  msg: string;
}
@Injectable()
export class LoaderService {
  isLoading = new Subject<loaderObj>();

  constructor() { }
  /**
   * Method for showing loader
   */
  showLoader(msg = "") {
    let obj = {
      show: true,
      msg
    };
    this.isLoading.next(obj);
  }
  /**
   * Method for hiding loader
   */
  hideLoader() {
    let obj = {
      show: false,
      msg: ""
    };
    this.isLoading.next(obj);
  }
}
