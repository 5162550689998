import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ServiceUrlConstants } from "../../core/global/constants/serviceurl-constants";
import { AjaxServices } from "../../core/services/ajax-services/ajax-service.service";
import { ClaimsBeneficiary, GetClaimsBeneficiariesResponse } from "../models/claims-beneficiary-model";
import { ClaimsRemarksType } from "../models/claims-remarks-type-model";
import { contractAttemptTypes } from "../models/contract-attempt-types-model";
import { contractAttemptedScheduleTypeModel } from "../models/contract-attempted-schedule-type-model";
import { BeneficiariesOnContractResponse, BeneficiaryOnContract } from "../models/contract-beneficiary-response-model";
import { DueDeligenceStatus } from "../models/due-deligence-status-model";
import { IntercationTopics } from "../models/interaction-topics-model";
import { InteractionType } from "../models/interaction-type-model";
import { InteractionWrapupModel } from "../models/interaction-wrapup-model";
import { RemarksModel } from "../models/remarks-model";
import { RoleInteraction } from "../models/role-interaction-model";
import { contractReasearchStatusOption } from "../models/claims-reasearch-status-model";
import { TransactionRequestWrapperModel } from "src/app/core/global/interface/transaction-request-wrapper-model";
import { marketClosedDate } from "../models/market-closed-dates-model";
import { nextContactDueDateResponseModel } from "../models/next-contact-due-date-response-model";
import { ContractForNOD } from "../models/contract-for-nod-response-model";
import moment from "moment";
import { DatePipe } from "@angular/common";
import { RelationshipModel } from "../models/relationships-model";
import { DeathNoticeTransactionWrapper } from "src/app/core/global/interface/death-notice-transaction-wrapper.model";
import { EligibleTrnsactionForPurposePayload } from "src/app/core/global/interface/eligible-transaction-purpose-payload.model";
import { ServiceUrl } from "src/app/core/services/service-url.model";
import { EligibleTransactionList } from "src/app/core/global/interface/eligible-transaction-list.model";
import { HttpHeaders } from "@angular/common/http";
import { Country } from "../models/country.model";
import { UpdateDateOfDeathRequest, UpdateDateOfDeathResponse } from "../models/update-date-of-death.models";


@Injectable()
export class ClaimsService {
  constructor(
    private http: AjaxServices,
    private datePipe: DatePipe
  ) { }

  public getClaimsPortalAccess(token): Observable<any> {
    return this.http.getApi(
      ServiceUrlConstants.CLAIMS_PORTAL_ACCESS,
      { responseType: "text" as "json", token},
      null,
      true
    );
  }

  public fetchClaimsBeneficiary(request): Observable<GetClaimsBeneficiariesResponse> {
    return this.http.postApi(
      ServiceUrlConstants.CLAIMS_BENEFICIARY_LIST,
      request,
      null,
      true
    );
  }
  
  public fetchClaimsBeneficiaryRemarksType(request): Observable<ClaimsRemarksType[]> {
    return this.http.postApi(
      ServiceUrlConstants.CLAIMS_BENEFICIARY_REMARKS_TYPE,
      request,
      null,
      true
    );
  }
  public fetchClaimsInteractionType(): Observable<any> {
    return this.http.getApi(
      ServiceUrlConstants.CLAIMS_BENEFICIARY_INTERACTION_TYPE,
      null,
      null,
      true
    );
  }

  public  fetchClaimsInteractionRoles(): Observable<RoleInteraction[]> {
    return this.http.getApi(
      ServiceUrlConstants.CLAIMS_BENEFICIARY_INTERACTION_ROLE,
      null,
      null,
      true
    );
  }

  public getBeneficiariesContract(request):Observable<BeneficiariesOnContractResponse> {
    return this.http.postApi(
      ServiceUrlConstants.CLAIMS_BENEFICIARY_ON_CONTRACT,
      request,
      null,
      true
    );
  } 
  public fetchInteractionWarpupCodesByCatogryById(requestId): Observable<IntercationTopics[]>  {
    return this.http.postApi(
      ServiceUrlConstants.CLAIMS_BENEFICIARY_INTERACTION_WRAPUP_CODE_BY_CATEGORY_ID,
      requestId,
      null,
      true
    );
  }
  public fetchInteractionCategoriesbyByStemtype(): Observable<InteractionType[]>  {
    return this.http.getApi(
      ServiceUrlConstants.CLAIMS_BENEFICIARY_INTERACTION__CATEGORY_BY_SYSTEM_TYPE,
      null,
      null,
      true
    );
  }

  public addContractRemark(request:RemarksModel): Observable<any> {
    return this.http.postApi(
      ServiceUrlConstants.ADD_REMARKS,
      request,
      null,
      null
    );
 } 
 
  public createInteractionWrapup(request:InteractionWrapupModel): Observable<any> {
    return this.http.postApi(
      ServiceUrlConstants.CREATE_INTERACTIONWRAPUP,
      request,
      null,
      null
    );
  } 

  public getDueDiligenceStatus(request): Observable<DueDeligenceStatus[]> {
    return this.http.postApi(
      ServiceUrlConstants.CLAIM_DUE_DILIGENCE_STATUS,
      request,
      null,
      null
    );
  }
  public getContactAttemptTypes(request): Observable<contractAttemptTypes[]> {
    return this.http.postApi(
      ServiceUrlConstants.CLAIM_NEXT_CONTRACT_ATTEMPT,
      request,
      null,
      true
    );
  } 

  public getContactAttemptScheduleTypes(request): Observable<contractAttemptedScheduleTypeModel[]> {
    return this.http.postApi(
      ServiceUrlConstants.CLAIM_ATTEMPT_SCHEDULE_TYPE,
      request,
      null,
      null
    );
  } 

  public getContactReasearchStatus(request): Observable<contractReasearchStatusOption[]> {
    return this.http.postApi(
      ServiceUrlConstants.CLAIM_REASEARCH_STATUS,
      request,
      null,
      null
    );
  } 

  public saveTransaction(request): Observable<any> {
    return this.http.postApi(
      ServiceUrlConstants.CLAIMS_SAVE_TRANSACTION,
      request,
      null,
      true,
    )
  }
  
  getEligibleTransactionForPurpose(data:EligibleTrnsactionForPurposePayload):Observable<EligibleTransactionList>{
    const updatedServiceurlObj: ServiceUrl = {
        ...ServiceUrlConstants.GET_ELIGIBLE_TRANSACTION_FOR_PURPOSE,
        endPointUrl: `${ServiceUrlConstants.GET_ELIGIBLE_TRANSACTION_FOR_PURPOSE.endPointUrl}`
    } 
    return this.http.postApi(updatedServiceurlObj, data, null, true);
}

  public getMarketClosedDates(request): Observable<marketClosedDate[]> {
    return this.http.postApi(
      ServiceUrlConstants.GET_MARKET_CLOSED_DATES,
      request,
      null,
      null
    );
  } 
  public getSystemMaintenance(contractType): Observable<boolean> {
    return this.http.postApi(
      ServiceUrlConstants.GET_SYSTEM_MAINTENANCE,
      contractType,
      null,
      true
    );
  }
  public saveSendClaimKitDetails(request : any): Observable<any> {
    return this.http.postApi(
      ServiceUrlConstants.SAVE_SEND_CLAIMS_KIT_DETAILS,
      request,
      null,
      true
    );
  }
  public updateDateOfDeath(request : UpdateDateOfDeathRequest): Observable<UpdateDateOfDeathResponse> {
    return this.http.postApi(
      ServiceUrlConstants.UPDATE_DATE_OF_DEATH,
      request,
      null,
      true
    );
  }

  public getNextContactDueDate(requestedDays: number): Observable<nextContactDueDateResponseModel> {
    return this.http.postApi(
      ServiceUrlConstants.GET_NEXT_CONTACT_DUE_DATE,
      requestedDays,
      null,
      true
    );
  }

  public getContractForNOD(contractNumber : String): Observable<ContractForNOD> {
    return this.http.postApi(
      ServiceUrlConstants.SEARCH_CONTRACT_FOR_NOD,
      contractNumber,
      null,
      true
    );
  }

  public addContractToClaim(request): Observable<TransactionRequestWrapperModel> {
    return this.http.postApi(
      ServiceUrlConstants.ADD_CONTRACT_TO_CLAIM,
      request,
      null,
      true,
    )
  }
  public getRelationships(contractType): Observable<RelationshipModel[]> {
    return this.http.postApi(
      ServiceUrlConstants.GET_RELATIONSHIPS,
      contractType,
      null,
      true
    );
  }

  /**
   * @method nextWeekdayAfterWeekend
   * @description to get Monday if current date is Saturday or Sunday
   */
  nextWeekdayAfterWeekend(date: Date) {
    if (date.getDay() === 0) {
      date.setDate(date.getDate() + 1);
    } else if (date.getDay() === 6) {
      date.setDate(date.getDate() + 2);
    }
    return date;
  }
  /**
   * @method nextBusinessDate
   * @description to get next working day if current date is Holiday
   */
  nextBusinessDate(date: Date, callback: CallableFunction) {
    date = this.nextWeekdayAfterWeekend(date);
    let request = {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().add(1,"y").format("YYYY-MM-DD")
    }
    this.getMarketClosedDates(request).subscribe(data => {
      if(data.length) {
        const nonBusinessDates= data.map(ele=>ele.effectiveDate);
        nonBusinessDates.forEach(element => {
          if (element === this.datePipe.transform(date, 'MM/dd/yyyy')) {
            date.setDate(date.getDate() + 1);
            this.nextWeekdayAfterWeekend(date);
          }
        });
      }
      callback(date);
    })
    
  }

  public saveTransactionExternal(request): Observable<DeathNoticeTransactionWrapper> {
    return this.http.postApi(
      ServiceUrlConstants.CLAIMS_SAVE_TRANSACTION_EXT,
      request,
      null,
      false,
    )
  }

  public saveScreeenshot(request: { requestDate: string;
    transactionRequestId: string;
    confirmationNumber: string;
    transactionType: string;}): Observable<{status: string}> {
    return this.http.postApi(
      ServiceUrlConstants.CLAIMS_SAVE_SCREEENSHOT,
      request,
      null,
      true,
    )
  }


  public uploadDocs(request: FormData):Observable<{fileName: string; errorMessage: string;}>{
      return this.http.postApi(ServiceUrlConstants.API_UPLOAD_DOCS, 
        request, 
        null,
        false
        )

  }

  getCountries():Observable<Country[]>{
    return this.http.getApi(ServiceUrlConstants.GET_COUNTRIES_INT, null, null,true) as Observable<Country[]>;
  }

}

