import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

  transform(value: string, field:string): string {
    switch(field.toLowerCase()){
     case 'ssn': {
      if (value && value.length === 9) {
        value = value.replace('XXX-XX-', '');
        return "XXX-XX-" + (value.slice(5, 9));
      } else {
        return value;
      }
     }
     case 'dob' :{
      if(value && value.length){
      return (value.slice(0, 5)) + "/XXXX";
      }
      return null;
     }
     default: {
       return value;
    }
    }
  }

}
