import { Component, Input } from '@angular/core';
import { DecedentInfo } from 'src/app/claims-management/models/decedent-info-model';
import { UtilityService } from 'src/app/core/services/utility.service';

@Component({
  selector: 'app-collapsable-decedent-info',
  templateUrl: './collapsable-decedent-info.component.html',
  styleUrls: ['./collapsable-decedent-info.component.scss'],
})
export class CollapsableDecedentInfoComponent {
  isDecedentViewCollapsed: boolean;
  constructor(
    private utilityService: UtilityService,
  ) { }

  get isCollapsed() {
    return this.isDecedentViewCollapsed;
  }
  @Input() set isCollapsed(value: boolean) {
    this.isDecedentViewCollapsed = value;
  }
  @Input() isToggleable = true;
  decedentInfo: DecedentInfo = this.utilityService.getSessionStorageData("decedentData");
  decedentName:string = this.decedentInfo ? (this.decedentInfo.firstName +' '+ (this.decedentInfo.middleName ? (this.decedentInfo.middleName+' '):'')+ this.decedentInfo.lastName) : '';

  toggleChange(value:boolean){
     this.isCollapsed =value;
  }

}
