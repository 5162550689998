import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { OKTA_AUTH } from "@okta/okta-angular";

import { OktaAuth } from "@okta/okta-auth-js";
import { ClaimsService } from '../../../claims-management/services/claims.service';
import { AuthService } from '../auth.service';
import { UtilityService } from '../utility.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    isAuthenticated: boolean;
    currentServiceObj: any;
    currentUser: any;
    constructor(
        private utilityService: UtilityService,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
        private authService: AuthService,
        private claimsService:ClaimsService
        ) { }
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> { 
        if (!this.currentServiceObj) {
            this.fetchServiceHost();
        }
        this.isAuthenticated = await this.oktaAuth.isAuthenticated();
        if (!this.isAuthenticated) {
            this.oktaAuth.signInWithRedirect();
        } else {
            const tokenStorage = JSON.parse(
                sessionStorage.getItem("okta-token-storage")
            );
            const accessToken = tokenStorage ? (tokenStorage.accessToken ? tokenStorage.accessToken.accessToken : "") : "";
            return new Promise((resolve, reject) => {
                this.claimsService.getClaimsPortalAccess(accessToken).subscribe(response => {
                    sessionStorage.setItem("claims-access-token-storage", response);
                this.oktaAuth.getUser().then(res => {
                    this.utilityService.isCSRAuthenticated = true;
                        this.utilityService.setCsrUserData(res);
                        this.utilityService.setSessionStorageData("csrUserData", res);
                        resolve(true);
                    });
                  
                })
            })
        }
    }
    /**
     * @method fetchServiceHost
     * @description It will get environment information by checking window location
     */
    public fetchServiceHost() {
        this.utilityService.fetchServiceHost();
        this.currentServiceObj = this.utilityService.fetchCurrentServiceObj();
    }
}
