export const REDIRECT_MVC_LOCALHOST = "https://localhost:8443";
export const MVC_PATH = "/webportal";
/**
 * Service URLs for API calls
 */
export const ServiceUrlConstants = {
  NGCONFIG: {
    endPointUrl: "public/ngconfig",
    isProtected: false,
    isInternal: false
  },
  /*claims-portal service*/
  CLAIMS_PORTAL_ACCESS: {
    endPointUrl: "getclaimsaccess",
    isProtected: false,
    isInternal: true 
  },

  CLAIMS_BENEFICIARY_LIST: {
    endPointUrl: "getClaimBeneficiaries",
    isProtected: false,
    isInternal: true 
  },

  CLAIMS_BENEFICIARY_REMARKS_TYPE: {
    endPointUrl: "lookups/getRemarksFinType",
    isProtected: false,
    isInternal: true 
  },
  
  CLAIMS_BENEFICIARY_INTERACTION_TYPE: {
    endPointUrl: "lookups/getInteractionTypes",
    isProtected: false,
    isInternal: true 
  },

  CLAIMS_BENEFICIARY_INTERACTION_ROLE: {
    endPointUrl: "getInteractionRolesBySystemType",
    isProtected: false,
    isInternal: true 
  },

  CLAIMS_BENEFICIARY_ON_CONTRACT: {
    endPointUrl: "getBeneficiariesOnContract",
    isProtected: false,
    isInternal: true 
  },
  CLAIMS_BENEFICIARY_INTERACTION_WRAPUP_CODE_BY_SYSTEM_TYPE: {
    endPointUrl: "lookups/getinteractionwrapupcodesbysystemtype",
    isProtected: false,
    isInternal: true
  },

  CLAIMS_BENEFICIARY_INTERACTION_WRAPUP_CODE_BY_CATEGORY_ID: {
    endPointUrl: "getInteractionWrapupCodesByCategoryId",
    isProtected: false,
    isInternal: true
  },

  CLAIMS_BENEFICIARY_INTERACTION__CATEGORY_BY_SYSTEM_TYPE: {
    endPointUrl: "getInteractionCategoriesBySystemType",
    isProtected: false,
    isInternal: true
  },

  ADD_REMARKS: {
    endPointUrl: "addContractRemarks",
    isProtected: false,
    isInternal: true 
  },

   CREATE_INTERACTIONWRAPUP: {
    endPointUrl: "createInteractionWrapup",
    isProtected: false,
    isInternal: true 
  },
 
  USER_LOGOUT: {
    endPointUrl: "logoutService/logout",
    isProtected: true,
    isInternal: false
  },
 

  API_SERVER_SIDE_LOGGING: {
    endPointUrl: "serversidelogging",
    isProtected: false,
    isInternal: true
  },

  CLAIM_DUE_DILIGENCE_STATUS: {
    endPointUrl: "lookups/getDueDeligenceStatus",
    isProtected: false,
    isInternal: true 
  },

  CLAIM_NEXT_CONTRACT_ATTEMPT: {
    endPointUrl: "lookups/getContactAttemptTypes",
    isProtected: false,
    isInternal: true 
  },

  CLAIM_ATTEMPT_SCHEDULE_TYPE: {
    endPointUrl: "lookups/getContactAttemptScheduleTypes",
    isProtected: false,
    isInternal: true 
  },
  CLAIM_REASEARCH_STATUS: {
    endPointUrl: "lookups/getResearchStatus",
    isProtected: false,
    isInternal: true 
  },
  CLAIMS_SAVE_TRANSACTION: {
    endPointUrl: "saveTransactions",
    isProtected: false,
    isInternal: true 
  },
  GET_MARKET_CLOSED_DATES: {
    endPointUrl: "getMarketClosedDates",
    isProtected: false,
    isInternal: true 
  },
  GET_SYSTEM_MAINTENANCE: {
    endPointUrl: "getSystemMaintenance",
    isProtected: false,
    isInternal: true 
  },
  SAVE_SEND_CLAIMS_KIT_DETAILS: {
    endPointUrl: "saveClaimsKit",
    isProtected: false,
    isInternal: true
  },
  UPDATE_DATE_OF_DEATH: {
    endPointUrl: "updateDateOfDeath",
    isProtected: false,
    isInternal: true
  },
  CSR_AUDIT_INFO: {
    endPointUrl: "audit/saveClaimAudit",
    isProtected: false,
    isInternal: true
  },
  GET_NEXT_CONTACT_DUE_DATE: {
    endPointUrl: "getNextContactDueDateByScheduleType",
    isProtected: false,
    isInternal: true
  },
  SEARCH_CONTRACT_FOR_NOD: {
    endPointUrl: "searchContractForDeathNotice",
    isProtected: false,
    isInternal: true
  },
  ADD_CONTRACT_TO_CLAIM: {
    endPointUrl: "addContractToClaims",
    isProtected: false,
    isInternal: true
  },
  PRIVACY: {
    endPointUrl: "/corporate-api/content/privacy",
    isProtected: false,
    isInternal: false
  },
  CA_PRIVACY_POLICY: {
    endPointUrl: "/corporate-api/content/caprivacypolicy",
    isProtected: false,
    isInternal: false
  },
  GET_STATES_EXT:{
      endPointUrl: "public/getstates",
      isProtected: true,
      isInternal: false
  },
  GET_COUNTRIES_EXT:{
    endPointUrl: "public/getcountries",
    isProtected: true,
    isInternal: false
  },
  GET_RELATIONSHIPS_EXT:{
    endPointUrl: "public/getRelationships",
    isProtected: true,
    isInternal: false
  },
  GET_PUBLIC_ACCESS: {
    endPointUrl: "public/getaccess",
    isProtected: false,
    isInternal: false
  },
  VALIDATE_INFO:{
    endPointUrl: "public/validateinfo",
    isProtected: true,
    isInternal: false
  },
  GET_ELIGIBLE_TRANSACTION_FOR_PURPOSE:{
    endPointUrl: "getEligibleTransactionForPurpose",
    isProtected: true,
    isInternal: false
  },
  GET_RELATIONSHIPS: {
    endPointUrl: "lookups/getRelationships",
    isProtected: false,
    isInternal: true 
  },
  AUDIT_INFO_EXT: {
    endPointUrl: "audit/saveClaimAudit",
    isProtected: true,
    isInternal: false
  },
  CLAIMS_SAVE_TRANSACTION_EXT: {
    endPointUrl: "saveTransactions",
    isProtected: true,
    isInternal: false 
  },
  CLAIMS_SAVE_SCREEENSHOT: {
    endPointUrl: "saveConfirmationPreview",
    isProtected: true,
    isInternal: false 
  },
  API_GET_DISCLOSURE_EXT: {
    endPointUrl: "getDisclosure",
    isProtected: true,
    isInternal: false
  },
  API_UPLOAD_DOCS: {
    endPointUrl: "uploadDocument",
    isProtected: true,
    isInternal: false,
    bypassContentType:true
  },
  GET_COUNTRIES_INT:{
    endPointUrl: "lookups/getCountries",
    isProtected: false,
    isInternal: true
  }
};
