import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbModule } from "primeng/breadcrumb";
import { PrimebuttonComponent } from './primebutton/primebutton.component';
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { InputTextModule } from "primeng/inputtext";
import { InputMaskModule } from "primeng/inputmask";
import { CardModule } from "primeng/card";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { TableModule } from "primeng/table";
import { RadioButtonModule } from "primeng/radiobutton";
import { SplitButtonModule } from "primeng/splitbutton";
import { CheckboxModule } from "primeng/checkbox";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClaimsDataTableComponent } from './claims-data-table/claims-data-table.component';
import { NumbersDirective } from './directives/numbers.directive';
import { PanelModule } from "primeng/panel";
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NumberPipePipe } from './pipes/number-pipe.pipe';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { USCode } from './pipes/us-code.pipe';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { FooterComponent } from './footer/footer.component';
import { StepperComponent } from './stepper/stepper.component';
import { CollapsableDecedentInfoComponent } from './collapsable-decedent-info/collapsable-decedent-info.component';
import { MaskPipe } from './pipes/mask.pipe';
import { isForeignPipe } from './pipes/isForeignAddress.pipe';
import { FormatFieldPipe } from './pipes/format-field.pipe';
import { DividerModule } from 'primeng/divider';
import { TabViewModule } from 'primeng/tabview';
import { AcknowledgementSectionComponent } from './acknowledgement-section/acknowledgement-section.component';
import { TaxpayerCertificationInformationComponent } from './taxpayer-certification-information/taxpayer-certification-information.component';
import { NonResidentAlienComponent } from './non-resident-alien/non-resident-alien.component';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { CalendarModule } from "primeng/calendar";
import { DateRangePickerPrimeComponent } from './date-range-picker-prime/date-range-picker-prime.component';


@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbComponent,
    PrimebuttonComponent,
    DateRangePickerPrimeComponent,
    ClaimsDataTableComponent,
    NumbersDirective,
    NumberPipePipe,
    USCode,
    FooterComponent,
    StepperComponent,
    CollapsableDecedentInfoComponent,
    MaskPipe,
    isForeignPipe,
    FormatFieldPipe,
    AcknowledgementSectionComponent,
    TaxpayerCertificationInformationComponent,
    NonResidentAlienComponent,
    CountryNamePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    MessagesModule,
    MessageModule,
    InputTextModule,
    InputMaskModule,
    CardModule,
    DialogModule,
    ButtonModule,
    TableModule,
    RadioButtonModule,
    SplitButtonModule,
    CheckboxModule,
    PanelModule,
    AutoCompleteModule,
    NgxDaterangepickerMd.forRoot(),
    FileUploadModule,
    ToastModule,
    DividerModule,
    TabViewModule,
    CalendarModule
  ],
  exports: [
    HeaderComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbComponent,
    PrimebuttonComponent,
    DateRangePickerPrimeComponent,
    MessagesModule,
    MessageModule,
    InputTextModule,
    InputMaskModule,
    CardModule,
    DialogModule,
    ButtonModule,
    TableModule,
    RadioButtonModule,
    SplitButtonModule,
    CheckboxModule,
    ClaimsDataTableComponent,
    NumbersDirective,
    PanelModule,
    AutoCompleteModule,
    NumberPipePipe,
    MaskPipe,
    FormatFieldPipe,
    isForeignPipe,
    NgxDaterangepickerMd,
    USCode,
    FileUploadModule,
    ToastModule,
    FooterComponent,
    StepperComponent,
    CollapsableDecedentInfoComponent,
    DividerModule,
    TabViewModule,
    AcknowledgementSectionComponent,
    CountryNamePipe,
    CalendarModule
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    USCode,
]
})
export class SharedModule { }
