
<div class="container chart-div" id="death-notice-confirmation"  >
    <div class="row mt-2 mb-3 component-align">
        <div class="table-responsive centerAlign mt-1">
            <app-stepper [activeAction]="activeAction" [pendingActions]="pendingActions"
                [completedActions]="completedActions" [isPrintPage]="isPrint" data-html2canvas-ignore="true"></app-stepper>
        </div>
    </div>
    <div class="row mb-3 mt-5 login-div-mainheading component-align">
        <div class="col-12 fw-bold ps-0 thank-you-color">
            Your request has been successfully submitted.
        </div>
    </div>

    <!-- thank you card -->
    <div class=" mb-3 confirmation-text component-align-thank-you">
        <div class="container">
            <div class="col-12">
                <span [innerHTML]="labelConstants.CONFIRMATION_TEXT"></span>
            </div>
        </div>
    </div>

    <!-- confirmation, effectivedate and print section -->
    <div class="row mt-3 component-align">
        <div class="col-xs-12 col-sm-12 col-md-9 pe-0 ps-0">
            <div class="row pt-2 pt-md-0">
                <div class="info-label-financial fw-bold col-xs-5 col-sm-5 col-md-5 col-lg-4 col-xl-3 pe-0 ps-0">
                    {{labelConstants.CONFIRMATION_NUMBER}}
                </div>
                <div
                    class="info-value-financial confirm-align text-size fw-bold col-xs-5 col-sm-5 col-md-5 col-lg-6 col-xl-5 ps-0 pe-0 ">
                    {{wrapper?.transactionRequest?.confirmationNumber}}
                </div>
            </div>
            <div class="row mt-4">
                <div class="info-label-financial fw-bold col-xs-5 col-sm-5 col-md-6 col-lg-6 col-xl-3 pe-0 ps-0">
                    {{labelConstants.TRANS_EFFECTIVE_DATE}}
                </div>
                <div
                    class="info-value-financial text-size fw-bold col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 ps-0 pe-0 ">
                    {{wrapper?.transactionDetails[0]?.effectiveDate | date: 'MM/dd/yyyy'}}
                </div>
            </div>
            
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12 order-first order-md-last text-end " *ngIf = "!isPrint">
            <app-primebutton [btnIcon]="'pi pi-print'" [isPrimary]="false" type="button" [title]="'Print'"
                [btnLabel]="'Print'" (clickEvent)="printTransactionConfirmation()">
            </app-primebutton>
        </div>
    </div>

    <!-- decedent Info -->
    <div class="row mt-2 component-align component-margin">
        <app-collapsable-decedent-info [isCollapsed]="false" [isToggleable]="false"></app-collapsable-decedent-info>
    </div>
    <!-- agent info -->
    <div class="row mt-2 component-align component-margin" *ngIf="contractOwnerRelationship!=='beneficiary'">
        <app-notifier-info [isEditable]="false"></app-notifier-info>
    </div>
    <!-- benelist -->
    <div class="row mt-2 component-align component-margin">
        <app-beneficiary-list-review [isEditable]="false"></app-beneficiary-list-review>
    </div>
    <!-- Uploaded Document section -->
    <div class="row mt-2 component-align component-margin" >
        <app-uploaded-document [uploadedDocuments]="uploadedDocuments"></app-uploaded-document>
    </div>
    <div class="row mt-2 component-align component-margin">
        <app-acknowledgement-section [wrapper]="wrapper" [contractOwnerRelationship]="contractOwnerRelationship"
        [pageName]="'Confirmation'"[isPrintPage]="isPrint" >
        </app-acknowledgement-section>
    </div>
    <div class="row mt-2 component-align component-margin" *ngIf="disclosure && isPrint">
        <app-contract-disclosure [disclosureModel]="disclosure">
        </app-contract-disclosure>
    </div>
    <!-- done btn -->
    <!-- Commenting Done Button as per requirement-->
    <!-- <div class="text-end component-align" *ngIf = "!isPrint">
        <app-primebutton [btnIcon]="'pi pi-angle-double-right'" [iconPos]="'right'" [isPrimary]="true"
            type="button" [title]="'Done'"
            [btnLabel]="'Done'"
            (click)="closeWindow()">
        </app-primebutton>
    </div> -->
    <!-- need more help section -->
    <div class="row mb-3 row-alignment" *ngIf = "!isPrint">
        <div class="col-6 text-end">Need more help?</div>
        <div class="col-6">
            <p-button label="See Our FAQ" title="See Our FAQ" styleClass="btn-style" (click)="goToFaq()">
            </p-button>
        </div>
    </div>
</div>
