import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClaimsManagementConstants } from '../../claims-management/constants/claims-management-constant';
import { ReviewAckOptions } from 'src/app/core/global/interface/death-notice-transaction-details-model';

@Component({
  selector: 'app-taxpayer-certification-information',
  templateUrl: './taxpayer-certification-information.component.html',
  styleUrls: ['./taxpayer-certification-information.component.scss']
})
export class TaxpayerCertificationInformationComponent {

  public claimsManagementConstants= ClaimsManagementConstants;
  public backupWithholding = false;
  nraOptionsValue: ReviewAckOptions;

  @Input() pageName: string;

  get reviewAckOptions() {
    return this.nraOptionsValue;
  }
  @Input() set reviewAckOptions(value) {
    if(value) {
      this.nraOptionsValue = value;
      this.updateField(this.nraOptionsValue);
    }
  }

  @Output() subjectToWithholdingChange = new EventEmitter<boolean>();

  updateField(nraOptions: ReviewAckOptions) {
    if (nraOptions) {
      if (nraOptions.backupWithholding) {
        this.backupWithholding = true;
      } else {
        this.backupWithholding = false;
      }
    }
  }
  /**
   * @method OnChangeSubjectToWithholding
   * @param event 
   * @description On change of subjectToWithholding checkbox emit the event
   */
  onChangeSubjectToWithholding(){
    this.subjectToWithholdingChange.emit(this.backupWithholding);
  }

}
