import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '../../core/services/utility.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() logoutEvenet = new EventEmitter();
  public isCsr : boolean = false;
  constructor(
    public router: Router,
    public utilityService: UtilityService) {

  }
  ngOnInit() {
    this.isCsr = this.utilityService.isCsr;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.utilityService.idle.stop();
    this.utilityService.showIdlePopup = false;
    this.utilityService.resetUserProfileData();
    this.utilityService.captureAnalytics(
      "User Logout",
      "loggedOut",
      "Logout"
    );
    this.logoutEvenet.emit();
    this.router.navigate(["/public/logout"]);
  }

}
