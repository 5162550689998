import { Injectable } from "@angular/core";
import { EnvironmentInterface } from "./ajax-services/ajax-service-interface";
import { Idle } from "@ng-idle/core";
import * as CryptoJS from "crypto-js";
import { ENVIRONMENTCONFIG, PROXYPATH } from "src/environments/environment.prod";
import { DatePipe, KeyValue } from "@angular/common";
import { BehaviorSubject } from "rxjs";
//import { ENVIRONMENTCONFIG, PROXYPATH } from "src/environments/environment.prod";
//import { ENVIRONMENTCONFIG, PROXYPATH } from "portal-claims/src/environments/environment.prod";
import html2canvas from "html2canvas";
import { PrinterPageComponent } from "src/app/public/printer-page/printer-page.component";
import { PublicNgConfigInterface } from "../global/interface/public-ngconfig-data.model";
import { ServiceUrl } from "./service-url.model";
import { CurrentServiceObj } from "../global/interface/current-service-object.model";
import { ServiceUrlConstants } from "../global/constants/serviceurl-constants";
declare var window: any;
@Injectable()
export class UtilityService {

  private userData;
  private currentServiceHostObj: EnvironmentInterface;
  public showIdlePopup;
  private tokenInfo = null;
  private csrUserData;
  private cliamsBeneList;
  private currentServiceObj = {
    publicWebsiteHost:"",
    internalHost:"",
    externalHost:"",
    serviceHost: "",
    integraionNameInternal: "",
    integrationNameExternal: "",
    integrationNamePublic: "",
    internalBasePath: "",
    externalBasePath: "",
    tokenInternal: "",
    clientId: "",
    csrGroupConfig: {
      csrGroup: "",
      csrView: "",
      csrProcessor: "",
      csrAdmin: "",
      csrClaimsProcessor: ""
    },
    issuer: "",
    isJsExceptionLogAllowed: false
  };
  public isCsr = false;
  currentSystemDetails = {};
  public base_href;
  public chatQue;
  static publiclssk: string;
  static encryptSecretKey = "12345@FGHI0";
  static  serverLogLevel = 3;
  isCSRAuthenticated = true;
  public globalErrors = [];
  public toastConfig = new BehaviorSubject<any>(null);
  public toastConfig$ = this.toastConfig.asObservable();
  printAppended: boolean;
  static publicNgConfigData:PublicNgConfigInterface
  static currentServiceObject: CurrentServiceObj;

  // S-28540
  // creating a class member fetchCurrentServiceObject to get currentServiceObject
  static fetchCurrentServiceObject() :void{
    let currentServiceObj = {
      serviceHost: "",
      integraionNameInternal: "",
      integrationNameExternal: "",
      internalBasePath: "",
      externalBasePath: "",
      tokenInternal: "",
      tokenExternal: "",
      clientId: "",
      issuer: "",
      claimsExternalHost: ""
    };
    const host = window.location.origin.toLowerCase();
    let isCsr = false;
    let currentServiceHostObj;
    for (const item of Object.keys(ENVIRONMENTCONFIG)) {
      currentServiceHostObj = ENVIRONMENTCONFIG[item];
      currentServiceObj = {
        serviceHost: "",
        integraionNameInternal: currentServiceHostObj
          .integraionNameInternal,
        integrationNameExternal: currentServiceHostObj
          .integrationNameExternal,
        internalBasePath: "",
        externalBasePath: "",
        tokenInternal: currentServiceHostObj.tokenInternal,
        tokenExternal: currentServiceHostObj.tokenExternal,
        clientId: currentServiceHostObj.clientId,
        issuer: currentServiceHostObj.issuer,
        claimsExternalHost: currentServiceHostObj.claimsExternalHost
      };
      if (ENVIRONMENTCONFIG[item].internalHost === host) {
        isCsr = true;
        currentServiceObj.serviceHost = currentServiceHostObj.serviceInternal;
        currentServiceObj.internalBasePath = PROXYPATH.csrUser.internal;
        currentServiceObj.externalBasePath = PROXYPATH.csrUser.external;

        break;
      } else if (ENVIRONMENTCONFIG[item].externallbHost === host) {
        isCsr = false;
        currentServiceObj.serviceHost = currentServiceHostObj.serviceExternalLb;
        currentServiceObj.internalBasePath = PROXYPATH.csrUser.internal;
        currentServiceObj.externalBasePath = PROXYPATH.csrUser.external;

        break;
      } else if (ENVIRONMENTCONFIG[item].externalHost === host) {
        isCsr = false;
        currentServiceObj.serviceHost = currentServiceHostObj.serviceExternal;
        currentServiceObj.internalBasePath = PROXYPATH.nonCsrUser.internal;
        currentServiceObj.externalBasePath = PROXYPATH.nonCsrUser.external;
        break;
      }
    }
    if (!currentServiceHostObj.apiGatewayConfigured) {
      /**
       * making internal and external basepath to empty string as api gateway is not configured for this environment
       */
      currentServiceObj.internalBasePath = "";
      currentServiceObj.externalBasePath = "";
    }
    UtilityService.currentServiceObject = currentServiceObj;
  }
    /**
   * @description Used to get web service header
   * @param serviceUrlObj is a ServiceUrl type object
   */
    static getXApiKey(serviceUrlObj: ServiceUrl) {
      const xApiKey = serviceUrlObj.isInternal
        ? UtilityService.currentServiceObject.tokenInternal
        : UtilityService.currentServiceObject.tokenExternal;
      if (!serviceUrlObj.isProtected) {
        return xApiKey || '';
      } else {
        return null;
      }
    }
 
    static getNgConfigURL():string{
      return UtilityService.getServiceURL(ServiceUrlConstants.NGCONFIG)
    }

    static getServiceURL(serviceUrlObj: ServiceUrl): string {
      if (!UtilityService.currentServiceObject) {
        UtilityService.fetchCurrentServiceObject();
      }
      // if CSR user trying to access Internal API resources then use Internal stage name or else use external stage name
      if (serviceUrlObj.isInternal) {
        return (
          `${UtilityService.currentServiceObject.serviceHost}${UtilityService.currentServiceObject.integraionNameInternal}` +
          `${UtilityService.currentServiceObject.internalBasePath}${serviceUrlObj.endPointUrl}`
        );
      }
      return (
 `${UtilityService.currentServiceObject.serviceHost}` +
      `${UtilityService.currentServiceObject.integrationNameExternal}${UtilityService.currentServiceObject.externalBasePath}${serviceUrlObj.endPointUrl}`
      );
  }

  constructor(public idle: Idle, private datePipe: DatePipe) { 

  }
 private isPrint:boolean;
  getUserProfileData() {
    return this.userData;
  }


   /**
   * @method setCsrUserData
   * @description seter for csr user data
   * @param data UserClaims type object. Which is returned by okta user service.
   */
    public setCsrUserData(data) {
      this.csrUserData = data;
    }
    /**
     * @method getCsrUserData
     * @description geter for csr user data
     * @returns user data which is UserClaims type object
     */
    public getCsrUserData() {
      return this.csrUserData;
    }
  /**
 * Method for setting encryption decryption key
 * @access public
 */
 static setEncryptionKey(encKey) {
    this.encryptSecretKey =
      encKey && encKey.lssk ? encKey.lssk : this.publiclssk;
  }
  /**
* Method for resetting user variable
* @access pulbic
*/
  resetUserProfileData() {
    this.userData = {};
  }

  public fetchServiceHost() {
    const host = window.location.origin.toLowerCase(); 
    for (const item of Object.keys(ENVIRONMENTCONFIG)) {
      this.currentServiceHostObj = ENVIRONMENTCONFIG[item];
      this.currentServiceObj = {
        publicWebsiteHost: this.currentServiceHostObj.publicWebsiteHost,
        internalHost: this.currentServiceHostObj.internalHost,
        externalHost: this.currentServiceHostObj.externalHost,
        serviceHost: "",
        integraionNameInternal: this.currentServiceHostObj
          .integraionNameInternal,
        integrationNameExternal: this.currentServiceHostObj
           .integrationNameExternal,
        integrationNamePublic: this.currentServiceHostObj
        .integrationNamePublic,
        internalBasePath: "",
        externalBasePath: "",
        tokenInternal: this.currentServiceHostObj.tokenInternal,
        clientId: UtilityService.publicNgConfigData.oktaclientid,
        csrGroupConfig: UtilityService.publicNgConfigData.csrGroupConfig,
        issuer: UtilityService.publicNgConfigData.oktaissuer,
        isJsExceptionLogAllowed: UtilityService.publicNgConfigData.isJsExceptionLogAllowed
      };
      if (ENVIRONMENTCONFIG[item].internalHost === host) {
        this.isCsr = true;
        this.currentServiceObj.serviceHost = this.currentServiceHostObj.serviceInternal;
        this.currentServiceObj.internalBasePath = PROXYPATH.csrUser.internal;
        this.currentServiceObj.externalBasePath = PROXYPATH.csrUser.external;
        break;
      }
      if (ENVIRONMENTCONFIG[item].externalHost === host) {
        this.isCsr = false;
        this.currentServiceObj.serviceHost = this.currentServiceHostObj.serviceExternal;
        this.currentServiceObj.internalBasePath = PROXYPATH.nonCsrUser.internal;
        this.currentServiceObj.externalBasePath = PROXYPATH.nonCsrUser.external;
        break;
      }
      if (ENVIRONMENTCONFIG[item].externallbHost === host) {
        this.isCsr = false;
        this.currentServiceObj.serviceHost = this.currentServiceHostObj.serviceExternal;
        this.currentServiceObj.internalBasePath = PROXYPATH.nonCsrUser.internal;
        this.currentServiceObj.externalBasePath = PROXYPATH.nonCsrUser.external;
        break;
      }    
    }
  }

  /**
* @method fetchCurrentServiceObj
* @description returns env specific service url related config object.
*/
  fetchCurrentServiceObj() {

    return this.currentServiceObj;
  }

  /**
* @method: getInternalUserScope
* @description: get the scope for internal user
*/
  getInternalUserScope() {
    return "openid profile email groups";
  }

  /**
* Method for setting token data
* @access public
*/
  setTokenInfo(data) {
    this.tokenInfo = data;
  }
  /**
   * Method for getting token data
   * @access public
   */
  getTokenInfo() {
    return this.tokenInfo;
  }
  /**
* @method captureAnalytics
* @description: method to capture the ga analytics
*/
  captureAnalytics(category, action, label) {
    const str1 = "'";
    const str2 = "'";
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: "GaEvent",
      eventCategory: str1.concat(category, str2),
      eventAction: str1.concat(action, str2),
      eventLabel: str1.concat(label, str2),
    });
  }

  
  currentBrowser() {

    const unknown = '-';

    // screen
    let screenSize = '';
    if (screen.width) {
      const width: string | number = (screen.width) ? screen.width : '';
      const height: string | number = (screen.height) ? screen.height : '';
      screenSize += '' + String(width) + " x " + String(height);
    }

    // browser
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browser = navigator.appName;
    let version = '' + String(parseFloat(navigator.appVersion));
    let majorVersion = parseInt(navigator.appVersion, 10);

    // Opera
    if (nAgt.indexOf('Opera') !== -1) {
      browser = 'Opera';
      version = nAgt.substring(nAgt.indexOf('Opera') + 6);
      if (nAgt.indexOf('Version') !== -1) {
        version = nAgt.substring(nAgt.indexOf('Version') + 8);
      }
    }
    // Opera Next
    if (nAgt.indexOf('OPR') !== -1) {
      browser = 'Opera';
      version = nAgt.substring(nAgt.indexOf('OPR') + 4);
    } else if (nAgt.indexOf('Edge') !== -1) { // Legacy Edge
      browser = 'Microsoft Legacy Edge';
      version = nAgt.substring(nAgt.indexOf('Edge') + 5);
    } else if (nAgt.indexOf('Edg') !== -1) { // Edge (Chromium)
      browser = 'Microsoft Edge';
      version = nAgt.substring(nAgt.indexOf('Edg') + 4);
    } else if (nAgt.indexOf('MSIE') !== -1) { // MSIE
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(nAgt.indexOf('MSIE') + 5);
    } else if (nAgt.indexOf('Chrome') !== -1) { // Chrome
      browser = 'Chrome';
      version = nAgt.substring(nAgt.indexOf('Chrome') + 7);
    } else if (nAgt.indexOf('Safari') !== -1) { // Safari
      browser = 'Safari';
      version = nAgt.substring(nAgt.indexOf('Safari') + 7);
      if (nAgt.indexOf('Version') !== -1) {
        version = nAgt.substring(nAgt.indexOf('Version') + 8);
      }
    } else if (nAgt.indexOf('Firefox') !== -1) { // Firefox
      browser = 'Firefox';
      version = nAgt.substring(nAgt.indexOf('Firefox') + 8);
    } else if (nAgt.indexOf('Trident/') !== -1) { // MSIE 11+
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    } else if ((nAgt.lastIndexOf(' ') + 1) < (nAgt.lastIndexOf('/'))) { // Other browsers
      browser = nAgt.substring(nAgt.lastIndexOf(' ') + 1, nAgt.lastIndexOf('/'));
      version = nAgt.substring(nAgt.lastIndexOf('/') + 1);
      if (browser.toLowerCase() === browser.toUpperCase()) {
        browser = navigator.appName;
      }
    }
    // trim the version string
    if (version.indexOf(';') !== -1) {
      version = version.substring(0, version.indexOf(';'));
    }
    if (version.indexOf(' ') !== -1) {
      version = version.substring(0, version.indexOf(' '));
    }
    if (version.indexOf(')') !== -1) {
      version = version.substring(0, version.indexOf(')'));
    }

    majorVersion = parseInt('' + version, 10);
    if (isNaN(majorVersion)) {
      version = '' + String(parseFloat(navigator.appVersion));
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    // mobile version
    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    // cookie
    let cookieEnabled = (navigator.cookieEnabled) ? true : false;

    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = (document.cookie.indexOf('testcookie') !== -1) ? true : false;
    }

    // system
    let os = unknown;
    const clientStrings = [
      { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
      { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
      { s: 'Windows Vista', r: /Windows NT 6.0/ },
      { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
      { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
      { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
      { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
      { s: 'Windows 98', r: /(Windows 98|Win98)/ },
      { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
      { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: 'Windows CE', r: /Windows CE/ },
      { s: 'Windows 3.11', r: /Win16/ },
      { s: 'Android', r: /Android/ },
      { s: 'Open BSD', r: /OpenBSD/ },
      { s: 'Sun OS', r: /SunOS/ },
      { s: 'Chrome OS', r: /CrOS/ },
      { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
      { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
      { s: 'Mac OS X', r: /Mac OS X/ },
      { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: 'QNX', r: /QNX/ },
      { s: 'UNIX', r: /UNIX/ },
      { s: 'BeOS', r: /BeOS/ },
      { s: 'OS/2', r: /OS\/2/ },
      { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
    ];
    // tslint:disable-next-line: forin
    for (const [id] of clientStrings.entries()) {
      const cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    let osVersion: string| RegExpExecArray = unknown;

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS':
      case 'Mac OS X':
      case 'Android':
        osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        // tslint:disable-next-line: no-bitwise
        osVersion = String(osVersion[1]) + '.' + String(osVersion[2]) + '.' + String((osVersion[3] as string | 0));
        break;
    }
    this.currentSystemDetails = {
      screen: screenSize,
      browser,
      browserVersion: version,
      browserMajorVersion: majorVersion,
      mobile,
      os,
      osVersion,
      cookies: cookieEnabled
    };
  
  }

  digitsOnly(event) {
    // Allow only backspace and delete and also allowing numpad keys
    if (event.keyCode === 46 || event.keyCode === 8 || (event.keyCode > 95 && event.keyCode < 106)) {
      // let it happen, don't do anything
    } else {
      // Ensure that it is a number and stop the keypress
      if (event.keyCode > 31 && (event.keyCode < 48 || event.keyCode > 57)) {
        event.preventDefault();
      }
    }
    // for digits only avoid navigation and other keys
    return (
      event.ctrlKey ||
      event.altKey ||
      (47 < event.keyCode && event.keyCode < 58 && event.shiftKey === false) ||
      (95 < event.keyCode && event.keyCode < 106) ||
      event.keyCode === 8 ||
      event.keyCode === 9 ||
      (event.keyCode > 34 && event.keyCode < 40) ||
      event.keyCode === 46
    );
  }

   /**
   * Method for setting cliamsBeneficiaryList
   * @access pulbic
   */
    setCliamsBeneficiaryList(data) {
      this.cliamsBeneList = data;
    }
    
    /**
     * Method to get cliamsBeneficiaryList
     * @access pulbic
     */
    getCliamsBeneficiaryList() {
      return this.cliamsBeneList;
    }

    /**
   * Method for setting session storage data
   * @param cachekey
   * @param data
   */
  setSessionStorageData(cachekey: string, data, applyTheme = "venerable"): any {
    sessionStorage.setItem(cachekey, this.encryptData(data));
  }

  /**
   * Menthod for getting session storage data
   * @param cachekey
   */
  getSessionStorageData(cachekey: string) {
    const storageData = sessionStorage.getItem(cachekey);
    if (!storageData) {
      return null;
    }
    return this.decryptData(storageData);
  }

    /**
   * @description Method for encrypting data using crypto js
   * @param data
   */
     encryptData(data) {
      try {
        return CryptoJS.AES.encrypt(JSON.stringify(data), UtilityService.encryptSecretKey, {
          keySize: 128 / 8,
          iv: UtilityService.encryptSecretKey,
          mode: CryptoJS.mode.OFB,
          padding: CryptoJS.pad.NoPadding,
        }).toString();
      } catch (e) {
        // error
      }
    }

     /**
   * Method for decrypting data using crypto js
   * @param data
   */
  decryptData(data) {
    try {
      return JSON.parse(
        CryptoJS.enc.Utf8.stringify(
          CryptoJS.AES.decrypt(data, UtilityService.encryptSecretKey, {
            keySize: 128 / 8,
            iv: UtilityService.encryptSecretKey,
            mode: CryptoJS.mode.OFB,
            padding: CryptoJS.pad.NoPadding,
          })
        )
      );
    } catch (e) {
      // error
    }
  }

    /**
   * checks for valid characters
   * @returns boolean
   */
     checkInvalidCharacters(inputValue) {
      const pattern = /[^a-zA-Z0-9!@#$%^&*()_+-=\][{}|:"?><;',./` ]/g;
      const str: string = inputValue.replace(pattern, "");
      return str;
    }

     /**
  * @method convertEstDateStringToDateObject
  * @param date EST date without time formatted date like yyyy-MM-dd or MM-dd-yyyy
  * @returns  EST date with current EST time 
  */
  public convertEstDateStringToDateObject(date: string) : Date {
    if(date) {
      const effectiveDate = new Date(date); // new Date() method assumes date string as a UTC date even though its EST
      const effectiveDateObject = {
        year: effectiveDate.getUTCFullYear(),
        month: effectiveDate.getUTCMonth(),
        date: effectiveDate.getUTCDate()
      };
      const estDate = new Date(new Date().toLocaleString("en-US", {
        timeZone: "America/New_York"
      }));
      estDate.setFullYear(effectiveDateObject.year);
      estDate.setMonth(effectiveDateObject.month);
      estDate.setDate(effectiveDateObject.date);
      return new Date(estDate);
    }
    return null;
  }
  /**
     * @method isCsrClaimProcessor
     * @description checks whether user has CSR-Claims-Processor group
     * @returns true if user has group 'CSR-Claims-Processor' but not csr-admin or csr-processor
     */
  public isCsrClaimProcessor(): boolean {
    if (this.csrUserData && this.csrUserData[UtilityService.publicNgConfigData.csrGroupConfig.csrGroup]) {
      return this.csrUserData[UtilityService.publicNgConfigData.csrGroupConfig.csrGroup].includes(UtilityService.publicNgConfigData.csrGroupConfig.csrClaimsProcessor) as boolean;
    } else {
      return false;
    }
  }

    /**
   * @method numerical
   * @description accept numerical value 
   */
    numerical(event) {
      const k = event.charCode;
      return (k === 8 || k === 32 || (k >= 48 && k <= 57));
    }

    static setLogLevel(data) {
      if (data && data.serverLogLevel) {
        this.serverLogLevel = data.serverLogLevel;
      }
    }

     /**
   * @method removeBadCharacters
   * @description removes percentage input badcharacter. it will alow 2 decimal number which is < 100 & >0
   */
  public removePercentInputBadCharacters(event) {
    const pattern = /^((0*)(1|[0-9]\d?)(\.\d{1,7})?|((0*)(1|[0-9]\d?)(\.)?)|((0*)100(\.0?0?)?)|(0*\.\d{1,7})|(\.))$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/.$/, "");
      if (event.target.value) {
        this.removePercentInputBadCharacters(event);
      }
    }
  }

  /**
     * @description sorts object key value pair by value 
     * uses - *ngFor="let item of countryList | keyvalue: valueAscOrder"
     */
  public valueAscOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return a.value.localeCompare(b.value);
  }

   /**
   * @description Method for encryptAndEncodeData data using crypto js
   * @param data
   */
   encryptAndEncodeData(data:string) {
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data),
      CryptoJS.enc.Base64.parse(UtilityService.encryptSecretKey),
      {
        keySize: 128 / 8,
        iv: CryptoJS.enc.Base64.parse(UtilityService.encryptSecretKey),
        mode: CryptoJS.mode.OFB,
        padding: CryptoJS.pad.NoPadding
      });
    return encodeURIComponent(encrypted.toString());
  }
    /**
   * @method goToFAQ
   * * @description - function to redirect to FAQ on public website
   */
     public goToFAQ(newWindow = true): void {
      const avoidEnv: string[] = ['dr', 'dr-pprd', 'pprd']; // blocking env for which public website is not there
  
      const currentServiceObj = this.fetchCurrentServiceObj();
  
      const enviormentType = !avoidEnv.some((item) =>
        currentServiceObj.internalHost.includes(item)
      );
      if (enviormentType) {
        window.open(
          currentServiceObj.publicWebsiteHost + '/#/fileaclaim',
          newWindow ? '_blank' :  '_self'
        ); //redirecting to FAQ (public website)
      }
    }

    
  getToastConfig() {
    return this.toastConfig$;
  }

  setToastConfig(config) {
    this.toastConfig.next(config);
  }


  // this method is used to capture the  image of the DOM

  async captureScreenshot(screenshotTarget: HTMLElement): Promise<string> {
    const canvas =  await html2canvas(screenshotTarget, { scale: 1 });
    const base64image: string = canvas.toDataURL("image/png");
    return base64image;
  }

  focusOnTile(idValue: string) {
    setTimeout(() => {
      const itemToScroll = document.getElementById(idValue);
      if (itemToScroll) {
        itemToScroll.scrollIntoView({ behavior: "smooth", block: 'start', inline: "nearest" });
      }
    }, 50);
  }

  /** 
  * @method transformDate
  * @param date
  * @returns date
  * @description pipe method to format date
  */
  transformDate(date, format:string) {
    if(date && date.length){
      if(format === 'YYYY-MM-DD'){
        const dateParts = date.split('/');
        if(dateParts.length===3){
          const year = dateParts[2];
          const month =dateParts[0].padStart(2,'0');
          const day = dateParts[1].padStart(2,'0');
          return `${year}-${month}-${day}`;
        }
      }

 if(format === 'MM/DD/YYYY'){
  const dateParts = date.split('-');
  if(dateParts.length===3){
    const year = dateParts[0];
    const month =dateParts[1].padStart(2,'0');
    const day = dateParts[2].padStart(2,'0');
    return `${month}/${day}/${year}`;
  }
 }

    }
    return null;
  }

  /** 
  * @method printTransactionConfirmation
  * @param dialogService
  * @returns void
  * @description method to open a dialog for print preview and download
  */
  printTransactionConfirmation(dialogService){
    const dialogwidth = "75%";
    const dialogheight = "95%";
    const ref =dialogService.open(PrinterPageComponent,{  
      data:{
        isPrintPage:true,
        componentName: 'deathNoticeConfirm'
      },         
       width: dialogwidth,
       height: dialogheight,
       baseZIndex: 99999,
       showHeader: true,
       styleClass: 'printPreview',
       contentStyle: {
           'max-height': '90%',
           'overflow-y': 'visible',
           height: dialogheight,
           'overflow-x': 'hidden',
           border: '1px solid #c8c8c8'
       }
  })

this.setIsPrint(true);
  }

setIsPrint(data:boolean){
  this.isPrint =data;
}
getIsPrint(){
  return this.isPrint;
}
//remove initialtype from b64 string
removeInitialTypeForB64Str(base64: string): string {
  return base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
}

  /** 
  * @method appendPrintSection
  * @returns void
  * @description method to append the html section into DOM that we want to print
  */
  appendPrintSection() {
    if (!this.printAppended) {
      // all the print pages should have the top div with the class 'printSectionclaimsexternal'
      // then only it will get printed in the print preview


      // getting the first element with the class printSectionclaimsexternal
      const printSection = document.getElementsByClassName("printSectionclaimsexternal")[0];
      // creating a deepclone of printSection with its descendents
      const domclone = printSection.cloneNode(true);
      if (domclone instanceof HTMLElement) {
        //checking  element with class container and removing the class if found
        if (domclone.querySelector('.container') && domclone.querySelector('.container').classList &&
          domclone.querySelector('.container').classList.length) {
          domclone.querySelector('.container').classList.remove('container');
        }
        // removing the element with id printPageHeader i.e. we're removing header (print and close buttons)
        if (domclone.querySelector('#printPageHeader')) {
          domclone.querySelector('#printPageHeader').remove();
        }
      }
      // getting the id of element we want to print
      let printSectionId = document.getElementById('printSectionclaimsexternal');
      if (!printSectionId) {
        // if printsectionId is not there then create a div with id printSectionclaimsexternal and append it to DOM body
        printSectionId = document.createElement('div');
        printSectionId.id = "printSectionclaimsexternal";
        document.body.appendChild(printSectionId);
      }

      printSectionId.innerHTML = "";
      // appending domclone(section to be printed) to printSectionId
      printSectionId.appendChild(domclone);

      //getting all elements having class printDialog
      const printDialogTable = printSectionId.getElementsByClassName("printDialog");

      while (printDialogTable.length) {
        if (printDialogTable && printDialogTable.length && printDialogTable[0]) {
          // removing printDialog class from 1st element having class printDialog
          printDialogTable[0].classList.remove("printDialog");
        }
      }
      this.printAppended = true;
    }
  }

  /** 
  * @method throttleMethod
  * @returns function
  * @description method to add some limit to a function call
  */
  throttleMethod(func: () => void, context: object, limit: number): () => void {
    let flag = true;
     return function() {
        if(flag) {
          func.apply(context);
          flag = false;
          setTimeout(() => {
            flag = true;
          }, limit);
        }
     }
  }
 /** 
  * @method openInNewTab
  * @param url url to open in new window/tab
  * @returns void
  * @description open in new tab
  */
  openInNewTab(url: string) {
    window.open(url,'_blank')?.focus();
  }
/** 
  * @method getBase64
  * @param file file blob
  * @param resolve resolve promise method
  * @param reject reject promsie method
  * @returns b64
  * @description converts blob from input type file to b64
  */
  getBase64(file: File, resolve, reject) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      reject(error)
    };
 }

  /**
    * @name formatDate
    * @param date Date class object
    * @description format the date to yyyy-mm-dd hh:mm:ss
    * 
   */
  formatDate(date:Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
  /**
     * @method getCurrentEstDate
     * @description returns Date object with current EST date time as local date object
     * new Date().toLocaleString("en-US", { timeZone: "America/New_York" }) returns current EST date time string.
     * Current EST date time string has been passed to new Date() which creates a local date object with same date time as EST.
     * @returns Date object
     */
  public getCurrentEstDate(): Date {
    return new Date(new Date().toLocaleString("en-US", { timeZone: "America/New_York" }));
  }

  static setPublicNgConfig(data:PublicNgConfigInterface){
      UtilityService.publicNgConfigData = data;
  }
}

