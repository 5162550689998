<section id="generic-table" [class.hide-pagination]="(dataSource !== null && dataSource.length <= paginatorLength)"
    *ngIf="(dataSource && dataSource.length) || showHeaderOnEmptyData" [ngClass]="{'printDialog':isPrintDialog, 'tableborder':tableborder}">
    <p-table #dt [autoLayout]="autoLayout" [columns]="cols" [value]="dataSource"
        class="default-table table-responsive color-table-generic default-table" [paginator]="paginator"
        [rows]="paginatorLength"  [paginatorPosition]="paginatorPosition"
        [responsive]="true" styleClass="extended-data-table-style-class"
        [style]="{width:tableWidth}"    [sortField]="(initSortField | async) ? sortField : sortField" [sortOrder]="sortOrder"
        [rowHover]="rowHover" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
        [ngClass]="tableClass ? tableClass : ''" [(first)]="first" [rowGroupMode]="rowGroupMode" [groupRowsBy]="groupRowsBy" [dataKey]="groupRowsBy">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <ng-container *ngFor="let col of columns; let i = index">
                    <th *ngIf="col['display']" class="header-table-AccountSummary venheader-titles-font"
                        [width]="autoLayout !== true ? col.width:''" [pSortableColumn]="col.field"
                        [pSortableColumnDisabled]="col.disablesort" [attr.colspan]="col['colspan'] ? col['colspan'] : 1"
                        [attr.rowspan]="col['rowspan'] ? col['rowspan'] : 1"
                        [ngClass]="{ 'text-align-right': col.align=== 'right','text-align-center': col.headerAlign=== 'center' ||
                         col.align=== 'center', 'ven-disable-th-pointer': !enableSort}">
                        <ng-container *ngIf="!col.headerType">
                            <span [ngClass]="{ 'bold-header': col.bold=== 'true','float-end': col.align=== 'right' }">
                                <span [ngClass]="col.type==='text'  ? col.cls : ''" [innerHTML]="col.header"></span>
                                <p-sortIcon *ngIf="enableSort && !col.disablesort" [field]="col.field"></p-sortIcon>
                            </span>
                        </ng-container>
                    </th>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="{{groupRowsBy ? 'body' : 'body'}}" let-datarow let-columns="columns"
            let-rowIndex="rowIndex">
            <tr *ngIf="datarow.actionType !== 'redText'" class="exteded-table-body-row" id="row-index-{{rowIndex}}"
            (click)="clickEnable ? rowEmit(datarow) :'' ">
                <ng-container *ngFor="let col of columns; let i = index">
                    <td [width]="autoLayout !== true ? col.width:''"
                        [ngClass]="{ 'text-align-right': col.align=== 'right','text-align-center': col.align=== 'center',
                        'cursor-style': clickEnable}"
                        *ngIf="col['display']" [attr.colspan]="col['colspan'] ? col['colspan'] : 1"
                        class="disable-link1">
                        <span class="p-column-title custom-mobile-heading-classs"
                        [ngClass]="{ 'link-cls': col.type === 'link'}" [innerHTML]="col.header"></span>
                        <ng-container *ngIf="col.type === 'tableRadio'">
                            <p-tableRadioButton eventEmit [value]="datarow" ></p-tableRadioButton>
                        </ng-container>
                       
                        <ng-container *ngIf="col.type === 'text'">
                            <div class="generic-td-style div-workbreak-breakall" *ngIf="datarow[col.field] ; else elseNA"
                                [ngClass]="{ 'float-end': col.align=== 'right', 'text-view-red': col.pendingTranView === 'red' }">
                                {{ datarow[col.field] }}
                            </div>
                            <ng-template #elseNA>Not on file</ng-template>
                        </ng-container>
                    </td>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template *ngIf="displayEmptyMessage" pTemplate="emptymessage" let-columns>
            <tr>
                <td class="text-center errorMessageCol" *ngIf="columns" [attr.colspan]="columns.length">
                    <div class="alert alert-danger card-title errorMessage">
                        {{emptyMessage ? emptyMessage : 'No matching records found'}}
                    </div>
                </td>
            </tr>
        </ng-template>  
        <ng-template *ngIf="paginator && dataSource " pTemplate="paginatorleft" let-state let-datarow>
            <span class="bg-white">
                Showing {{ state.page * state.rows + (state.totalRecords ? 1 : 0) }} to
                {{
                state.rows * (state.page + 1) >= state.totalRecords
                ? state.totalRecords
                : state.rows * (state.page + 1)
                }}
                of
                {{ state.totalRecords }} entries <span *ngIf="dataSource.length !== state.totalRecords">(filtered from
                    {{dataSource.length}} total entries)</span>
            </span>
        </ng-template>
    </p-table>
</section>