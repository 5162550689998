<ul *ngIf="activeAction " data-html2canvas-ignore="true" id="wrapper-ul"
  class="registration-ul-ribbontabs pb-0 mt-0 pt-0 mb-0 registration-ul-envelope registration-ul-containerhorizontal border-bottom-0"
  [ngClass]="stepperClass ? stepperClass : ''">
  <ng-container *ngFor="let action of completedActions; let i = index">
    <li [ngClass]="isRegistration ? 'registration-span-steps-reg px-0 mx-0 text-width border-start-0 border-end-0' :
      'registration-span-steps px-0 mx-0 border-start-0 text-width border-end-0'">
      <div class=" mx-0 border-start-0 border-end-0 ">
        <ng-container>
          <img  class="img-fluid width-cls exact-print"
            alt="ic_step_checked"
            src="{{windowOrigin}}/assets/images/ic_step-completed.svg" *ngIf="isVenerable"/>
        </ng-container>

        <div class="exact-print text-width registration-div-textcentered registration-div-fonttabs text-size" 
        [ngClass]="{'registration-div-textcentered-regi registration-div-fonttabs ven-mteal stepper-space' : 
        isRegistration,
        ' text-danger ': 
        (rejectFlow && action !== 'Initiate'),
        'd-none':(isPrintPage&& action === 'Review & Acknowledgement'),
       ' ven-mteal': 
       !(rejectFlow && action !== 'Initiate'),
      'printpagelongtext': (isPrintPage && action === 'Review & Acknowledgement'),
      'd-sm-none d-md-none d-xs-none' :  action === 'Review & Acknowledgement',
      'd-lg-none d-xl-block' : isPrintPage && action === 'Review & Acknowledgement',
      'd-lg-block' : !isPrintPage && action === 'Review & Acknowledgement'
    }">
          {{ action }}
        </div>
        <div *ngIf=" action === 'Review & Acknowledgement'"
        id="small-review"
          class=" d-sm-block d-md-block d-lg-none reviewAckText exact-print text-width registration-div-textcentered registration-div-fonttabs text-size"
          [ngClass]="{'registration-div-textcentered-regi registration-div-fonttabs ven-mteal stepper-space' : 
                isRegistration,
                ' text-danger ': 
                (rejectFlow && action !== 'Initiate'),
                'd-none':!isPrintPage,
               ' ven-mteal': 
               !(rejectFlow && action !== 'Initiate'),
              'printpagelongtext': (isPrintPage && action === 'Review & Acknowledgement'),
              'd-md-block d-lg-block d-xl-none d-xs-block' : isPrintPage && action === 'Review & Acknowledgement',
              'd-md-block d-lg-none' : !isPrintPage && action === 'Review & Acknowledgement'}" title="Review & Acknowledgement">
          Review & Ack...
        </div>
      </div>
    </li>
  </ng-container>
  <ng-container *ngIf="activeAction">
    <li [ngClass]="isRegistration ? 'registration-span-steps-reg px-0 mx-0 border-start-0 border-end-0' :
      'registration-span-steps px-0 mx-0 border-start-0 border-end-0'">
      <div class="  mx-0 border-start-0 border-end-0 ">
        <ng-container>
          <img *ngIf="isVenerable" class="img-fluid width-cls exact-print"
           alt="active"
            src="{{windowOrigin}}/assets/images/ic_step_active.svg" />
        </ng-container>

        <div class="exact-print text-width registration-div-textcentered registration-div-fonttabs text-size"
          [ngClass]="{'registration-div-textcentered-regi registration-div-fonttabs ven-white stepper-space': isRegistration, 
        ' text-danger ' : rejectFlow, 
        ' ven-white ' : !rejectFlow,
        'd-sm-none d-md-none d-lg-block' :  activeAction === 'Review & Acknowledgement'
       }">
          {{ activeAction }}
        </div>
        <div *ngIf="activeAction === 'Review & Acknowledgement'"
          class="d-none d-sm-block d-md-block d-lg-none reviewAckText  exact-print text-width registration-div-textcentered registration-div-fonttabs text-size"
          [ngClass]="
                            isRegistration
                              ? 'registration-div-textcentered-regi registration-div-fonttabs ven-white stepper-space'
                              :(rejectFlow ? 'registration-div-textcentered registration-div-fonttabs text-danger text-size' : 'registration-div-textcentered registration-div-fonttabs ven-white text-size')
                          " title="Review & Acknowledgement">
          Review & Ack...
        </div>
      </div>
    </li>
  </ng-container>
  <ng-container *ngFor="let action of pendingActions; let i = index">
    <li [ngClass]="isRegistration ? 'registration-span-steps-reg px-0 mx-0 border-start-0 border-end-0' :
      'registration-span-steps px-0 mx-0 border-start-0 border-end-0'">
      <div class="mx-0 border-start-0 border-end-0">
        <img *ngIf="isVenerable" class="img-fluid width-cls  exact-print"
        alt="to_be_completed"
          src="{{windowOrigin}}/assets/images/ic_step_tobecompleted.svg" />
        <div class="exact-print text-width" [ngClass]=" {
              'registration-div-textcentered-regi registration-div-fonttabs ven-mteal stepper-space': isRegistration,
            'registration-div-textcentered registration-div-fonttabs ven-mteal text-size': !isRegistration,
            'd-sm-none d-md-none d-lg-block' :  action === 'Review & Acknowledgement'}
          ">
          {{ action }}
        </div>

        <div *ngIf="action === 'Review & Acknowledgement'"
          class="d-none d-sm-block d-md-block d-lg-none exact-print text-width reviewAckText" [ngClass]="
                isRegistration
                  ? 'registration-div-textcentered-regi registration-div-fonttabs ven-mteal stepper-space'
                  : 'registration-div-textcentered registration-div-fonttabs ven-mteal text-size'
              " title="Review & Acknowledgement">
          Review & Ack...
        </div>
</div>

    </li>
  </ng-container>
</ul>
