<div class="acknowledgement-card-div mb-3 mt-1 box-shadow-remove" id ="acknowledgement-card" >
    <div class="container">
        <div class="ven-dteal row mb-3 login-div-mainheading">
          <div class="col-sm-8 col-md-4 col-lg-4 heading-align">
            <span class="fw-color">
                Acknowledgement 
            </span>
          </div>
        </div>
        <div *ngIf="contractOwnerRelationship === 'beneficiary'">
        <ng-container>
          <form name="acknowledgementForm" id="acknowledgementForm" class="form-group col px-0" [formGroup]="acknowledgementForm" *ngIf="pageName !=='Confirmation'" data-html2canvas-ignore="true">
            <!-- <div class="row pt-4 pe-0"></div> -->
            <div class="row pt-3 pe-0 d-flex align-items-center">
                <span class="fw-bold fw-bold ps-0 col-lg-8 ">To complete this
                  transaction a verification of your SSN/TIN is required  
                  <sup class="ven-span-error">*</sup>:
                </span>
                <span class="ps-0 col-lg-4">
                  <input id="ssnTinCheck" name="ssnTinCheck" formControlName="ssnTinCheck" (keypress)="numerical($event)"
                    (input)="checkValidSSN($event)" maxlength="9" minlength="9" class="form-control border-form-control-even"
                    pInputText  data-html2canvas-ignore="true"/>
                  
                  <p-message severity="error" text="SSN/TIN is required" *ngIf="(acknowledgementForm.controls.ssnTinCheck.errors?.required) &&
                    (acknowledgementForm.controls.ssnTinCheck.touched || acknowledgementForm.controls.ssnTinCheck.dirty)">
                  </p-message>

                  <p-message severity="error" text="SSN/TIN should be 9 digits" *ngIf="acknowledgementForm.controls.ssnTinCheck.invalid && 
                    acknowledgementForm.controls.ssnTinCheck.errors?.pattern && 
                    acknowledgementForm.controls.ssnTinCheck.dirty">
                  </p-message>

                  <p-message severity="error" text="Invalid SSN/TIN.  Please retry" *ngIf="inputSSNValid && !validTIN_SSN">
                  </p-message>

                </span>
            </div>    
          </form>
        </ng-container>

        <!--<div class="row mt-1 ps-4" *ngIf="wrapper">-->
            <div class="info-label-financial-text col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 mt-3 acknowledgement-content">
              <app-taxpayer-certification-information (subjectToWithholdingChange)="subjectToBackupWithholdingChange($event)" [pageName]="pageName"
              [reviewAckOptions]="reviewAckOptions">
              </app-taxpayer-certification-information>
            </div>
            <div class="info-label-financial-text col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 mt-3">
              <app-non-resident-alien (nonResidentAlienChange)="nonResidentAlienChange($event)"
                (countryOfResidenceChange)="countryOfResidenceChange($event)" [pageName]="pageName" [reviewAckOptions]="reviewAckOptions">
              </app-non-resident-alien>
            </div>
        </div>
      <!-- </div> -->
          
    
        
      <!-- <div class="row mt-4  ps-4"> -->
        <div class="col-12 ps-0 pb-2">
          <p-message severity="error" text="{{claimsManagementConstants.ACCKNOWLEDGE_ERROR}}"
              *ngIf="showAgreementNoClickError && !(isPrintPage || pageName==='Confirmation')"></p-message>
        </div>
        <div class="info-label-financial-text col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 mt-1 pb-2">
            <p-checkbox [(ngModel)]="requiredNotices" id="requiredNotices" binary="true" name="requiredNotices" value="false"
            [disabled]="(pageName === 'Confirmation' || !hyperlinkClicked)" (click)="onChangeRequiredNotice()" required>
            </p-checkbox>
            
            <span class="info-label-financial-text ms-2">{{claimsManagementConstants.ACKNOWLEDEGE_MSG_1}}
            </span>
          
            <span for="isTerm" class="info-label-financial-text">
              <a href="{{claimsManagementConstants.AGREEMENT_URL}}" target="_blank" rel="opener" [ngClass]="isPrintPage ? 'disabled-link':''" (click)="onAgreementLinkClick()">
                {{claimsManagementConstants.ACKNOWLEDEGE_MSG_2}}</a>
            </span>
        </div>
      <!-- </div> -->

  </div> 
</div>
