import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isForeign'
})
export class isForeignPipe implements PipeTransform {

  transform(value: string): string {
      if(value ==='Y')
       {
        return 'Yes';
       }
      else{
        return 'No';
      }
  }

}
