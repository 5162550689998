import { Component, Input, Output, EventEmitter } from "@angular/core";


@Component({
  selector: "app-primebutton",
  templateUrl: "./primebutton.component.html",
  styleUrls: ["./primebutton.component.scss"]
})
export class PrimebuttonComponent {
  @Input() btnClass?= "";
  @Input() btnIcon?= "";
  @Input() iconPos?= "left";
  @Input() btnLabel;
  @Input() disabled?= false;
  @Input() type = "button";
  @Input() isPrimary = true;
  @Input() name = "";
  @Input() id = '';
  @Input() colCls = "";
  @Input() defaultCss = true;

  @Output() clickEvent = new EventEmitter();

  constructor() { }
  /**
   * Method for handaling click event
   * @access public
   * @return void
   */
  handleClickEvent(event) {
    this.clickEvent.emit(event);
  }
}
