<div class="pt-1">
    <div class="col-12 row pb-1 px-0">
        <span class="pe-0 ps-0 fw-bold">
            {{claimsManagementConstants.TAXPAYER_CERTIFICATION_TITLE}}
        </span>
    </div>
    <div class="col-12 row pb-1 px-0">
        <span class="pe-0 ps-4">
            {{claimsManagementConstants.TAXPAYER_CERTIFICATION_CONTENT_TITLE}}
        </span>
    </div>
    <div class="col-12 row pb-1 ps-4">
        <span class="pe-0 ps-4">
            {{claimsManagementConstants.TAXPAYER_CERTIFICATION_POINT_1}}
        </span>
    </div>
    <div class="col-12 row pb-1 ps-4">
        <span class="pe-0 ps-4">
            {{claimsManagementConstants.TAXPAYER_CERTIFICATION_POINT_2}}
        </span>
    </div>
    <div class="col-12 row pb-1 ps-4">
        <div class="pe-0 ps-4">
            <p-checkbox id="subjectToWithholding" binary="true" name="subjectToWithholding" 
            (onChange)="onChangeSubjectToWithholding()" [(ngModel)]="backupWithholding" [disabled]="(pageName === 'Confirmation')" >
            </p-checkbox>
            <span class="ps-2">
                {{claimsManagementConstants.TAXPAYER_CERTIFICATION_SUB_POINT_2}}
            </span>
        </div>
    </div>
    <div class="col-12 row pb-1 ps-4">
        <span class="pe-0 ps-4">
            {{claimsManagementConstants.TAXPAYER_CERTIFICATION_POINT_3}}
        </span>
    </div>
</div>
