import { AfterContentInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UtilityService } from './core/services/utility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService]
})

export class AppComponent implements OnInit, AfterContentInit {
 public  showHeader: boolean;
 public currentUrl = "";
 title = 'portal-claims';
 showFooter: boolean;
 position: string;
 styleClass: string;


  constructor(private utilityService: UtilityService,
    private router: Router,private messageService: MessageService,
    private ref: ChangeDetectorRef) {
      this.globalToastSubscription();
  }
  ngOnInit() {
    this.showHeader = this.utilityService.isCSRAuthenticated || !this.utilityService.isCsr;
  }

  ngAfterContentInit(): void {
    this.router.events.forEach(event => {
      if (event instanceof NavigationStart) {
        this.currentUrl = event.url;
        this.showHeader = true;
        if (this.currentUrl.includes("logout")) {
          this.utilityService.isCSRAuthenticated = false;
          this.showHeader = this.utilityService.isCSRAuthenticated ;
        }
        if(event.url.includes('public')){
          this.showFooter = true;
          this.ref.detectChanges();
        }
      }
    });
  }

  claimsLogout() {
   this.utilityService.isCSRAuthenticated = false;
   this.showHeader = this.utilityService.isCSRAuthenticated;
  }

    //subscribe to toast messages
    globalToastSubscription() {
      this.utilityService.getToastConfig().subscribe(config => {
        if (config) {
          const { styleClass, position, ...toastConfig } = config;
          this.position = position;
          this.styleClass = styleClass || '';
          this.messageService.add(toastConfig)
        }
      })
    }
}
