export enum httpResponseStatusCode {
  notFound = 404,
  success = 200,
  invalidRequest = 400,
  notAuthenticated = 401,
  internalServerError = 500,
  notImplementedError = 501,
  badGatewayError = 502,
  notAuthorized = 403,
  connectionRefused = 0,
  serviceUnavailable = 503,
  timeExpiry = 504,
  conflict = 409
}

export enum ErrorLog {
  TRACE = 0,
  DEBUG = 1,
  INFO = 2,
  LOG = 3,
  WARN = 4,
  ERROR = 5,
  OFF = 6
}

export const errorMessages = Object.freeze({
  HttpCommonError:
    "Server responded with an internal error and it has been logged. Please contact administrator",
  HttpUnhandledError:
    "Server error is unhandled and it has been logged. Please contact administrator",
  NotAuthenticated: "User authentication failed. Please contact administrator",
  NotAuthorized:
    "The user does not have sufficient privileges. Please contact administrator to get access",
  NotImplementedServer:
    "Operation which you are trying to access is not available",
  BadGateway:
    "Application encountered an error while communicating to the Server",
  SessionExpired:
    "Your session is expired. Please try to reload the application",
  ServiceUnavailable: "Server did not respond or is unavailable",
  Conflict: "Data already exist.",
  InvalidRequest: "Submitted request is in-correct",
  TimeOut:
    "Server is taking more than expected time to respond. Please try again after sometime",
  NotFound: "Application encountered an error. Please contact administrator",
  ExceptionOccur:
    "Application encountered an error. Please contact administrator",
  defaultError: "Application encountered an error. Please contact administrator",
  HttpInternalCommonError: "Please try again and contact us if you continue to receive this error."
});

export enum toastError {
  summary= 'Error : Something Went Wrong',
  styleClass = 'sendLinkWidth',
  position = 'bottom-center',
  life = 15000,
  error = 'error'
}
