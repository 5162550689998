import { OAuthResponseType, OktaAuth } from '@okta/okta-auth-js';
import { UtilityService } from "../../services/utility.service";

export function OktaConfig(
    utilityService: UtilityService) { 
    const SCOPES = utilityService.getInternalUserScope();
    utilityService.fetchServiceHost();
    const currentServiceObj = utilityService.fetchCurrentServiceObj();
    const oktaConfig = {
        issuer: UtilityService.publicNgConfigData.oktaissuer?UtilityService.publicNgConfigData.oktaissuer: window.location.origin,
        redirectUri: `${window.location.origin}/claims/callback`,
        clientId: UtilityService.publicNgConfigData.oktaclientid ? UtilityService.publicNgConfigData.oktaclientid : '0',
        scopes: SCOPES.split(/\s+/),
        pkce: true,
        responseType: ["code"] as OAuthResponseType[],
        tokenManager: {
            storage: "sessionStorage",
            autoRenew: true
        },
        disableHostCheck: "true"
    };
    const oktaAuth = new OktaAuth(oktaConfig);
    return { oktaAuth };
}
