import { Component, Input } from "@angular/core";
import { MenuItem } from "primeng/api";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"]
})
export class BreadcrumbComponent {
  @Input() breadCrumbItem: MenuItem[];
  @Input() homeItem: MenuItem;
  @Input() lastClickEnable = false;
}
