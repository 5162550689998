import { Injectable, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { errorMessages, httpResponseStatusCode, toastError } from "../global/constants/error-const";
import { Router } from "@angular/router";
import { LoaderService } from "./loader.service";
import { UtilityService } from "./utility.service";
@Injectable()
export class ErrorService {
  constructor(
    private injector: Injector,
    private loaderService: LoaderService,
    private utilityservice: UtilityService,
    private router: Router
  ) { }

  /**
   * this method is used to show the error message based on error code.
   * @param message
   *
   */
  showError(message,errorMessage=''): void {
    this.loaderService.hideLoader();
    if(this.utilityservice.isCsr){
      this.router.navigate(['/csr/globalerror']);
    }
    else{
      this.setToastConfig(message)
    }
  }

  /**
   * this method is used to redirect user to the logout page base on the error code.
   *
   */
  async afterRedirection() {
    this.loaderService.hideLoader();
    if (this.utilityservice.isCsr) {
      sessionStorage.clear();
      this.injector.get(Router).navigate(["/public/logout"]);
    } else {
      this.utilityservice.idle.stop();
      this.utilityservice.showIdlePopup = false;
      // if (this.utilityservice.idleTimeoutModalInstance) {
      //   this.utilityservice.idleTimeoutModalInstance.hide();
      // }
      // this.modalService.dismissAll();

      sessionStorage.clear();
      this.utilityservice.resetUserProfileData();
      this.utilityservice.setTokenInfo(null);
      this.utilityservice.captureAnalytics(
        "User Logout",
        "API Issue",
        "Logout"
      );
      this.injector.get(Router).navigate(["public/unavailable"]);
    }
  }

  /**
   * Error handler.
   * @method handleAPIError
   * @description It is to handle the error
   * @param error
   */
  handleAPIError(error) {
    
    switch ((error as HttpErrorResponse).status) {
      case httpResponseStatusCode.notAuthorized: {
        // 403
        this.afterRedirection();
        break;
      }
      case httpResponseStatusCode.invalidRequest: {
        // 400
        this.showError(errorMessages.InvalidRequest,error.message);
        break;
      }
      case httpResponseStatusCode.notFound: {
        // 404
        this.showError(errorMessages.NotFound,error.message);
        break;
      }
      case httpResponseStatusCode.connectionRefused: {
        // 0
        this.showError(errorMessages.ServiceUnavailable,error.message);
        break;
      }
      case httpResponseStatusCode.serviceUnavailable: {
        // 503
        this.showError(errorMessages.ServiceUnavailable,error.message);
        break;
      }
      case httpResponseStatusCode.timeExpiry: {
        // 504
        this.showError(errorMessages.TimeOut,error.message);
        break;
      }
      case httpResponseStatusCode.conflict: {
        // 409
        this.showError(errorMessages.Conflict);
        break;
      }
      case httpResponseStatusCode.internalServerError: {
        // 500
        this.showError(errorMessages.HttpInternalCommonError,error.message);
        break;
      }
      case httpResponseStatusCode.notImplementedError: {
        // 501
        this.showError(errorMessages.NotImplementedServer,error.message);
        break;
      }
      case httpResponseStatusCode.badGatewayError: {
        // 502
        this.showError(errorMessages.BadGateway,error.message);
        break;
      }
      case httpResponseStatusCode.notAuthenticated: {
        // 401
        this.afterRedirection();
        break;
      }
      default: {
        break;
      }
    }
  }
      //setting the configuration for error toast message
  setToastConfig(errorMsg: string) {
    this.utilityservice.setToastConfig(
      {
        severity: toastError.error, 
        summary: toastError.summary,
        detail: errorMsg, 
        life: toastError.life,
        position: toastError.position,
        styleClass: toastError.styleClass
      }
    )
  }

}
