<div class="csr-header-container">
    <!-- <app-csr-header ></app-csr-header> -->
    <div class="header_frame" #csrHeader>
        <div class="container pt-1 pb-2 ven-div-wlandscape">
          <div class="row desk">
            <div class="col-8">
              <a
               
                target="_blank"
                rel="opener"
              >
                <img
                  class="img-fluid imgCls"
                  alt="Responsive image"
                  src="assets/images/ven_logo.svg"
                />
              </a>
            </div>
            <div class="col-4 text-end" *ngIf="isCsr">
              <button
                type="button"
                pButton
                (click)="logout()"
                label="Logout"
                class="p-element p-button-sm p-button p-component"
                styleClass="theme-button"
              ></button>
            </div>
          </div>
        </div>
        <nav
          class="navbar navbar-expand-lg venheader-bg-color navbar-dark mt-1 pb-0 navsec"
          #navsec
        ></nav>
      </div>
      <div [style.height.px]="csrHeader.offsetHeight">
        {{ csrHeader.offsetHeight }}
      </div>
      
</div>