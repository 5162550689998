<div *ngIf="showHeader">
 <app-header (logoutEvenet)="claimsLogout()"> </app-header>
</div>
<div class='min-h-root'>
    <router-outlet></router-outlet>
    <div class="toast-pos">
        <p-toast id="global-toast" preventOpenDuplicates="true" position="{{position}}" [baseZIndex]="9999999999" styleClass="{{styleClass}}"></p-toast>
    </div>
</div>
<app-loader></app-loader>
<app-footer *ngIf="showFooter" class="stick-to-bottom"></app-footer>