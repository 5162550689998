import { Injectable } from "@angular/core";
import { AjaxServices } from "./ajax-services/ajax-service.service";
import { ServiceUrlConstants } from "../global/constants/serviceurl-constants";
import * as CryptoJS from "crypto-js";
import { ServiceUrl } from "./service-url.model";
import { ValidateInfoResponse } from "src/app/claims-management/models/validate-info-response.model";
import { Observable } from "rxjs";
import { State } from "src/app/claims-management/models/state.model";
import { Country } from "src/app/claims-management/models/country.model";
import { RelationshipModel } from "src/app/claims-management/models/relationships-model";
import { DisclosureModel } from "../global/interface/disclosure.model";


@Injectable({
    providedIn: 'root'
  })
export class PublicService{
   
    constructor(
        private http: AjaxServices,
    ){}
    
    getStates():Observable<State[]>{
        return this.http.getApi(ServiceUrlConstants.GET_STATES_EXT, false, null, false, true) as Observable<State[]>;
    }

    getCountries():Observable<Country[]>{
        return this.http.getApi(ServiceUrlConstants.GET_COUNTRIES_EXT, false, null) as Observable<Country[]>;
    }

    validateInfo(encryptedSSN:string):Observable<ValidateInfoResponse>{
        const updatedServiceurlObj: ServiceUrl = {
            ...ServiceUrlConstants.VALIDATE_INFO,
            endPointUrl: `${ServiceUrlConstants.VALIDATE_INFO.endPointUrl}`
        }
        return this.http.postTokenApi(updatedServiceurlObj,{taxId:encryptedSSN},null,true) as Observable<ValidateInfoResponse>;
    }

    /**
   * @method getRelationships
   * @param contractType 
   * @description service call to get relationshipList
   */
    getRelationships(request):Observable<RelationshipModel[]>{
        return this.http.postApi(
            ServiceUrlConstants.GET_RELATIONSHIPS_EXT,
            request,
            null,
            true,
          ) as Observable<RelationshipModel[]>;
    }
 /**
   * @method getDisclosure
   * @param contractDisplay 
   * @description service call to get disclosures
   */
  public getDisclosure(contractDisplay: string): Observable<DisclosureModel> {
    return this.http.postApi(ServiceUrlConstants.API_GET_DISCLOSURE_EXT,
      contractDisplay, null, true) as Observable<DisclosureModel>;
  }
}